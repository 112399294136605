import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500 500">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform=''>
	
<path fill="#051E41" opacity="1.000000" stroke="none" 
	d="
M1.000000,175.000000 
	C1.000000,116.681107 1.000000,58.862213 1.000000,1.021660 
	C167.610611,1.021660 334.221222,1.021660 500.915924,1.021660 
	C500.915924,167.568970 500.915924,334.137970 500.915924,500.853516 
	C334.333435,500.853516 167.666733,500.853516 1.000000,500.853516 
	C1.000000,392.445312 1.000000,283.972656 1.000000,175.000000 
M307.748444,60.598560 
	C307.552795,60.321499 307.293579,60.130924 307.000000,59.999847 
	C307.000000,59.999847 306.973755,60.029388 306.727753,59.584297 
	C306.442841,59.407242 306.157959,59.230186 305.356323,58.655846 
	C291.247345,48.915215 276.036041,48.876347 260.590302,54.273586 
	C245.898743,59.407303 240.043152,79.803894 249.622574,92.105507 
	C252.635315,95.974411 256.643127,99.160172 260.528442,102.238365 
	C268.115875,108.249603 277.672180,109.028473 286.552490,111.656273 
	C289.106750,112.412125 292.597015,114.395020 293.128571,116.464935 
	C293.766846,118.950172 292.385193,122.653259 290.665680,124.901787 
	C286.886566,129.843613 277.142365,131.325668 269.881836,127.713684 
	C263.909180,124.742393 258.579132,120.479286 252.247375,116.317520 
	C249.688095,119.446869 246.544418,123.014420 243.800644,126.866814 
	C243.215424,127.688507 244.083847,129.545517 243.846237,131.337967 
	C246.182251,133.611664 248.518265,135.885361 251.085022,138.796936 
	C252.903641,140.434036 254.563751,142.303543 256.567108,143.669739 
	C267.814453,151.339920 280.584198,152.019775 293.466827,150.771774 
	C301.741180,149.970184 309.264374,146.874496 314.076385,139.305389 
	C320.270752,129.561951 319.026764,119.439796 315.796783,108.449852 
	C313.015564,103.873894 311.782013,97.883507 305.048859,96.120316 
	C304.445251,95.401215 303.981812,94.305984 303.216492,94.020813 
	C294.959045,90.943832 286.626862,88.066734 278.376465,84.971573 
	C275.408356,83.858078 272.605652,82.303757 269.781281,80.245987 
	C269.852386,79.780365 269.923462,79.314735 270.700745,78.799797 
	C272.700134,77.729980 274.608612,76.002075 276.715546,75.711105 
	C283.629669,74.756279 290.694885,73.284561 297.037964,78.159904 
	C298.365875,79.180557 300.395233,79.288536 302.145782,79.853813 
	C302.145782,79.853813 302.125061,79.907570 302.446899,80.351120 
	C302.952087,80.500870 303.457306,80.650627 304.644470,80.751770 
	C306.978607,78.327194 309.596619,76.113503 311.587219,73.433739 
	C315.613831,68.013161 315.216980,67.010574 308.938934,63.292271 
	C308.930267,62.896023 308.921600,62.499775 308.739075,61.635799 
	C308.553284,61.341770 308.295197,61.140514 308.000000,61.000610 
	C308.000000,61.000610 307.967743,61.036201 307.748444,60.598560 
M227.118271,171.138062 
	C226.514877,170.661697 225.911499,170.185349 225.143875,169.715118 
	C225.143875,169.715118 225.299210,169.661453 225.131943,169.027847 
	C218.877090,162.162704 211.878433,156.332901 202.596405,152.987350 
	C202.596405,152.987350 202.180862,152.909500 202.067368,152.364990 
	C202.051636,152.180252 202.035889,151.995499 202.579544,151.417191 
	C203.385696,151.433578 204.234406,151.619308 204.988754,151.429642 
	C206.378036,151.080399 208.628662,150.776901 208.862366,149.972580 
	C209.251328,148.634003 208.679520,146.709045 207.899323,145.394943 
	C205.449539,141.268707 202.363831,137.489594 200.205399,133.230911 
	C198.617020,130.096939 196.632477,128.635101 193.126328,128.036194 
	C192.665283,126.477364 192.605484,124.605728 191.675323,123.412621 
	C187.092072,117.533630 182.453918,111.676704 177.478271,106.132271 
	C174.582214,102.905151 174.267929,100.309853 177.230011,97.056091 
	C180.798691,93.135948 184.312195,89.128670 187.447968,84.863823 
	C189.664627,81.849014 191.213776,78.343430 193.729004,74.892601 
	C197.491425,72.592041 201.170593,70.229660 201.765991,64.979218 
	C204.914291,63.869186 207.497543,62.543037 204.034058,58.163425 
	C202.200027,50.458241 199.011353,48.306168 191.247116,49.412651 
	C189.461899,49.667065 187.439117,50.217354 185.871567,49.659489 
	C181.572723,48.129612 178.524765,50.408112 176.055740,52.946205 
	C172.841873,56.250023 170.439362,60.328396 167.526855,63.943890 
	C163.972382,68.356277 160.250992,72.634209 156.601791,76.970291 
	C156.160233,76.740013 155.718674,76.509735 155.277115,76.279457 
	C155.369324,75.072769 155.733276,73.812706 155.508484,72.668304 
	C154.296844,66.500549 161.315201,58.384838 151.226151,54.297775 
	C151.102951,54.247868 151.015839,54.033508 150.970078,53.878708 
	C149.765991,49.805218 147.024673,48.872780 143.023087,49.399925 
	C140.150467,49.778351 137.112946,48.901588 134.239243,49.276482 
	C131.697662,49.608044 128.531555,50.058659 128.854370,54.726048 
	C128.826630,55.444908 128.798904,56.163765 128.129120,57.165817 
	C127.100624,60.540474 125.219810,63.905380 125.181091,67.291351 
	C124.917244,90.360115 125.039223,113.433327 125.040886,136.505096 
	C125.041359,143.111252 129.217560,148.022263 136.851608,149.726501 
	C137.545258,149.790833 138.238907,149.855164 138.581604,150.521957 
	C124.757042,156.338715 113.398193,165.179352 104.827568,177.596893 
	C98.216110,187.175888 93.459343,197.438461 92.047310,209.091248 
	C92.047310,209.091248 92.082634,209.094650 91.724564,209.398590 
	C91.806267,209.960403 91.887970,210.522202 91.825050,211.699112 
	C91.803642,212.327774 91.782227,212.956436 91.372375,213.965942 
	C91.300400,214.313171 91.228416,214.660416 91.082588,215.690491 
	C91.130829,216.144470 91.179077,216.598450 91.116806,217.015503 
	C91.116806,217.015503 91.077065,217.125031 90.840729,217.258530 
	C90.686737,217.542847 90.755249,217.758972 91.047218,218.480591 
	C91.093506,218.666138 91.139786,218.851685 91.041161,219.672745 
	C91.075485,220.103699 91.109818,220.534637 90.960342,221.773285 
	C91.019470,222.868927 91.078598,223.964584 91.019722,225.953278 
	C90.974274,235.087769 92.505295,243.870804 96.943695,252.689896 
	C97.373848,253.436600 97.804001,254.183289 98.197205,255.535126 
	C98.703331,256.362274 99.209457,257.189453 99.867859,258.730804 
	C103.192413,263.509888 106.516968,268.288971 110.057266,273.709076 
	C115.474052,278.252441 120.890831,282.795807 126.914757,287.808228 
	C127.155083,287.895691 127.395409,287.983154 127.843239,288.438293 
	C128.233109,288.581940 128.622986,288.725616 129.052872,288.986877 
	C129.052872,288.986877 129.167038,288.937866 129.562271,289.464935 
	C145.901779,298.973785 163.335205,302.374084 182.650604,298.907898 
	C183.449844,298.655365 184.249084,298.402863 185.776993,298.205841 
	C187.133057,297.785889 188.489136,297.365906 190.629532,296.955811 
	C206.227371,292.363617 219.183609,283.755371 229.332565,271.078888 
	C240.015991,257.734802 245.723526,242.484955 246.343536,225.000168 
	C246.405075,224.723663 246.334427,224.486679 246.461578,223.519562 
	C246.510193,222.695175 246.558807,221.870789 246.726776,220.202576 
	C245.772293,214.469803 244.817825,208.737045 243.908829,202.234680 
	C240.268982,194.491241 236.629135,186.747787 232.860336,178.403534 
	C232.213745,177.647430 231.567154,176.891327 230.955795,176.046478 
	C230.955795,176.046478 230.867096,176.081818 230.698318,175.595642 
	C230.423447,175.404388 230.148575,175.213135 229.646149,174.619385 
	C229.480896,174.354797 229.277496,174.123825 229.011932,173.992706 
	C229.011932,173.992706 229.071686,173.955322 229.034134,173.334335 
	C228.452911,172.794968 227.871689,172.255615 227.005707,171.949234 
	C227.005707,171.949234 227.290665,171.709396 227.118271,171.138062 
M390.777466,57.361412 
	C389.559937,56.000515 388.342407,54.639614 386.791565,52.822777 
	C386.726440,52.646137 386.661346,52.469501 386.565308,51.511467 
	C385.611298,51.037701 384.666748,50.177868 383.701660,50.154144 
	C378.294678,50.021252 372.882782,50.090164 367.302795,50.090164 
	C366.793152,53.542652 366.124878,55.986641 366.118622,58.432335 
	C366.050201,85.078804 366.132324,111.725700 366.035889,138.371994 
	C366.023132,141.895325 366.931793,144.520508 369.844177,146.679138 
	C367.587921,149.489426 364.490845,151.987915 363.301880,155.194000 
	C362.002625,158.697327 360.212494,159.155930 357.088440,159.138245 
	C339.604675,159.039291 322.119110,159.191940 304.636444,159.023209 
	C301.164062,158.989731 299.113342,160.060318 297.555298,163.143845 
	C295.458557,167.293533 292.667328,171.099213 290.661835,175.286118 
	C288.755249,179.266571 286.174286,180.690552 281.689758,180.519989 
	C271.712952,180.140594 261.710205,180.298691 251.723236,180.498749 
	C250.351547,180.526230 249.009689,182.042831 247.654007,182.870010 
	C249.007507,183.651276 250.350220,185.088058 251.716187,185.110489 
	C263.036407,185.296432 274.364349,185.065582 285.682251,185.312897 
	C289.393219,185.393967 291.452423,184.062271 293.086731,180.882339 
	C295.288910,176.597458 298.044495,172.599625 300.317871,168.347778 
	C301.882996,165.420502 303.768494,164.082581 307.342499,164.128769 
	C323.991516,164.343994 340.645813,164.315231 357.295898,164.144272 
	C360.689117,164.109436 362.107941,165.129715 363.274811,168.418732 
	C365.782806,175.487961 371.386292,179.460449 378.773926,179.895828 
	C386.114502,180.328400 391.998077,177.096512 395.790619,170.593643 
	C399.774384,163.762878 398.192474,157.170975 394.897064,149.814240 
	C394.604462,142.907837 394.100464,136.002686 394.059448,129.094788 
	C393.943085,109.499519 393.978821,89.902939 394.080322,70.307510 
	C394.089020,68.625725 394.655609,66.689301 395.608063,65.319748 
	C398.141754,61.676632 396.934753,59.903248 392.609711,58.611439 
	C392.026459,58.416458 391.443176,58.221478 390.777466,57.361412 
M376.728546,444.830811 
	C377.514374,444.087280 378.300201,443.343719 378.884094,442.791229 
	C380.015564,443.987000 380.975769,445.001770 382.522491,446.394958 
	C383.332550,445.560150 384.755951,444.773895 384.826691,443.880554 
	C384.980499,441.938080 384.417877,439.943909 384.196198,437.965454 
	C383.867218,435.029510 384.895996,433.169250 388.653503,432.988037 
	C389.501709,437.456421 390.339325,441.869232 391.176971,446.282013 
	C392.002777,446.138672 392.828552,445.995300 393.654358,445.851929 
	C392.897461,441.005035 392.218933,436.143005 391.291687,431.328888 
	C391.165405,430.673248 389.780060,429.782288 389.005524,429.808960 
	C384.842804,429.952332 380.688904,430.351471 376.319427,430.678192 
	C376.006958,430.321869 375.326080,429.545349 374.645172,428.768829 
	C374.197662,428.824402 373.750122,428.879974 373.302612,428.935516 
	C373.302612,434.080322 373.263977,439.226318 373.422607,444.366241 
	C373.430115,444.610016 375.186859,444.799805 376.728546,444.830811 
M127.773521,304.545807 
	C124.139999,297.648529 122.850075,297.155457 117.600342,300.711975 
	C117.600342,300.711975 117.551720,300.645874 117.621773,299.915863 
	C113.687691,296.529602 109.852371,293.014801 105.742523,289.857330 
	C104.838089,289.162537 102.959114,289.736267 101.278198,289.673248 
	C101.278198,289.673248 101.208107,289.426849 101.525200,288.743774 
	C102.760277,285.961212 102.113289,283.796753 99.036690,283.179535 
	C96.193169,282.609070 93.510246,283.049316 92.458290,286.504303 
	C92.185555,287.400055 91.498596,288.169708 90.916000,288.841003 
	C90.916000,288.841003 90.714607,288.823853 90.714607,288.823853 
	C90.714607,288.823853 90.997299,289.001984 91.010544,289.807220 
	C90.648476,293.355682 90.290070,296.904510 89.923309,300.452484 
	C89.557388,303.992462 89.183105,307.531586 88.857025,310.645752 
	C98.453423,314.887421 99.685684,305.161224 104.886536,302.436401 
	C107.912758,304.277588 108.048775,306.065765 106.912300,309.480377 
	C104.359261,317.151093 103.379135,320.899445 114.215851,320.971344 
	C114.547874,320.973541 114.880165,320.934662 115.752663,320.959656 
	C116.190582,321.255341 116.628494,321.551025 117.058266,322.624390 
	C118.567627,324.281647 119.865280,326.295441 121.708748,327.390045 
	C122.301254,327.741913 125.219894,325.905670 125.550964,324.664215 
	C126.867935,319.725739 127.583214,314.626831 128.615845,309.626190 
	C128.615845,309.626190 128.572601,309.533600 129.238907,309.835999 
	C129.505829,312.478271 129.892853,315.115540 130.004181,317.764343 
	C130.105560,320.176300 129.907700,322.600861 129.970551,325.549438 
	C130.040695,325.904388 130.110855,326.259308 130.257996,327.341888 
	C134.383621,329.184052 138.402847,331.398071 142.703506,332.628357 
	C143.901917,332.971222 146.071121,330.543518 147.511871,329.103546 
	C148.072174,328.543579 147.850342,327.201080 147.986816,326.200104 
	C149.692719,327.683319 151.043701,328.857971 152.712936,330.672302 
	C152.776566,330.901794 152.840195,331.131287 152.950775,332.084229 
	C155.802292,338.282410 161.742172,331.073456 165.313354,334.782501 
	C165.439590,334.913605 167.722107,332.968536 169.095657,331.239807 
	C169.201584,330.493225 169.307510,329.746613 170.043854,328.968445 
	C171.711884,329.070679 173.456467,328.895569 175.032684,329.330475 
	C178.794968,330.368622 182.405197,332.084503 186.213333,332.793976 
	C189.971420,333.494080 192.991455,331.613159 195.066132,328.307892 
	C197.463562,324.488434 200.748077,325.433838 204.105209,326.727539 
	C212.240768,329.862640 218.682114,326.497559 220.970276,318.148224 
	C221.364731,316.708923 222.607376,315.502106 223.458237,314.187927 
	C224.500504,315.682678 225.542770,317.177429 226.650803,318.766510 
	C227.803665,316.385071 233.676453,318.382660 232.996460,312.988007 
	C232.996460,312.988007 232.946991,312.963531 233.663269,312.857208 
	C237.934189,311.435608 242.736389,310.734772 244.498520,304.725006 
	C245.681793,303.475922 248.307358,303.020599 246.103333,300.233429 
	C242.749954,295.992828 239.794037,291.437897 236.583908,286.895599 
	C234.148376,288.641510 232.031647,290.158905 229.911621,291.678650 
	C230.951523,294.703644 231.888107,297.428070 232.824677,300.152496 
	C232.571899,300.353149 232.319107,300.553802 232.066330,300.754456 
	C229.842850,298.977905 227.619385,297.201385 225.093567,295.183289 
	C222.822510,296.809845 220.567551,298.424866 217.593613,300.165344 
	C214.650497,300.799835 211.717102,301.904907 208.762131,301.966034 
	C203.427307,302.076385 199.259079,305.165344 199.529297,309.570770 
	C199.529297,309.570770 199.419434,309.624817 198.725189,309.486694 
	C196.531097,310.609863 194.336990,311.733032 191.876724,312.256104 
	C187.336838,306.561462 182.489105,306.757782 176.927277,312.917633 
	C174.531952,312.996521 172.136612,313.075439 169.148956,313.150757 
	C168.764847,313.068878 168.380737,312.987030 167.818954,312.186096 
	C164.738617,311.144257 161.719315,309.818298 158.560928,309.139832 
	C155.343491,308.448669 153.090485,309.730438 153.336334,314.200684 
	C153.221222,315.133636 153.106110,316.066620 152.312332,317.236053 
	C151.501190,318.739899 150.690063,320.243744 149.898392,321.711487 
	C146.572601,321.275604 143.535843,320.877594 139.819916,320.390594 
	C141.050430,315.423401 142.150391,310.983246 143.295761,306.359741 
	C138.369751,306.005646 133.059128,305.623871 127.908875,305.085785 
	C127.908875,305.085785 127.782364,305.270569 127.773521,304.545807 
M326.701141,245.922409 
	C327.508942,247.465820 328.414795,248.966553 329.108276,250.559723 
	C333.078033,259.679657 343.738647,263.883667 352.931335,259.909210 
	C362.309357,255.854630 366.408539,245.120483 362.181580,235.686691 
	C358.011963,226.381058 347.696167,222.510864 338.389618,226.636429 
	C332.234009,229.365204 329.135040,234.407135 327.452240,240.789017 
	C324.349060,240.789017 321.359894,240.780548 318.370789,240.790390 
	C295.913361,240.864365 273.455902,240.941940 250.256058,240.865036 
	C249.217026,241.699188 248.178009,242.533325 247.138977,243.367462 
	C248.062668,244.116669 248.986359,244.865860 250.572006,245.818832 
	C251.716980,245.758377 252.861954,245.697922 254.926254,245.825882 
	C278.614746,245.772522 302.303253,245.719162 326.701141,245.922409 
M429.382996,233.237396 
	C432.980469,230.230957 437.668518,227.881531 439.955292,224.085526 
	C445.214325,215.355698 440.911957,204.034286 431.686462,199.746338 
	C422.216003,195.344513 411.032623,199.511459 407.361664,209.349472 
	C405.990540,213.023987 404.113708,213.433060 400.607849,214.004547 
	C389.142517,215.873550 381.963684,211.726181 378.598541,200.664291 
	C378.171021,199.258881 377.083099,198.065002 376.561005,196.675476 
	C375.465729,193.760498 373.537048,192.821091 370.417206,192.883652 
	C360.762146,193.077225 351.004028,193.809296 341.467102,192.730560 
	C330.376221,191.476074 322.930115,194.789291 318.915833,205.311340 
	C317.668610,208.580551 315.158752,209.214096 311.805725,209.187714 
	C292.488983,209.035706 273.169678,209.047073 253.853424,209.231598 
	C251.808243,209.251114 248.043655,210.727875 248.029343,211.578217 
	C247.953293,216.101120 251.854568,214.063217 253.974380,214.087814 
	C270.459015,214.279266 286.991028,213.459274 303.418701,214.445663 
	C314.928528,215.136795 323.423889,212.906509 327.501587,201.208466 
	C328.440613,198.514572 330.512268,197.788757 333.360138,197.833328 
	C343.682678,197.994812 354.033691,198.317764 364.324921,197.726166 
	C370.101471,197.394104 373.126892,199.367203 375.172424,204.495682 
	C376.703308,208.333817 378.769379,212.028824 381.099121,215.445297 
	C382.109070,216.926392 384.329071,218.296463 386.089661,218.418823 
	C391.558838,218.798920 397.084930,218.344208 402.557861,218.696274 
	C404.048859,218.792175 406.149414,220.278717 406.746887,221.657257 
	C411.029144,231.537460 418.091919,235.496872 429.382996,233.237396 
M265.502350,269.104950 
	C261.003235,269.108582 256.486542,268.888458 252.014725,269.239655 
	C250.490753,269.359314 249.079636,270.916168 247.616959,271.816376 
	C249.133392,272.608734 250.644348,274.078583 252.167068,274.090851 
	C271.828583,274.249512 291.513885,274.751587 311.144806,273.962769 
	C320.425720,273.589844 326.736603,275.902344 330.586426,284.466431 
	C331.591858,286.703064 333.643799,288.453674 334.770111,290.655212 
	C336.372192,293.786591 338.805664,294.707794 342.097900,294.656555 
	C349.927765,294.534698 357.762878,294.690674 365.590302,294.502594 
	C369.083771,294.418671 371.166901,294.951538 372.471161,298.965179 
	C375.032806,306.848114 383.869995,311.341431 392.182068,309.911499 
	C399.931396,308.578369 406.462097,301.529205 407.057281,293.855377 
	C407.771698,284.644135 402.784607,277.257507 393.933502,274.417023 
	C386.815979,272.132904 376.268799,275.382324 373.830841,282.443634 
	C371.299103,289.776489 366.750214,289.883820 360.935608,289.461670 
	C358.780731,289.305176 356.545532,289.109894 354.448700,289.491425 
	C343.750519,291.438171 336.278381,287.568909 331.892059,277.588287 
	C331.235260,276.093781 329.833984,274.937073 329.103058,273.462524 
	C327.286316,269.797485 324.373566,268.995331 320.489929,269.031342 
	C302.495026,269.198212 284.497772,269.105011 265.502350,269.104950 
M131.348755,444.831238 
	C132.351044,442.563965 133.353317,440.296722 134.572601,437.538574 
	C135.892212,441.017487 136.923035,443.735107 137.953842,446.452698 
	C138.695541,446.430328 139.437225,446.407959 140.178925,446.385590 
	C141.758987,441.087769 143.339066,435.789978 144.919128,430.492157 
	C144.191376,430.159058 143.463623,429.825958 142.735870,429.492828 
	C141.642288,431.584473 140.548706,433.676117 139.102188,436.442810 
	C137.780579,433.924133 136.670578,431.808746 134.906113,428.446075 
	C133.196640,432.718353 131.978745,435.762054 130.290192,439.981995 
	C129.737411,437.084106 129.513062,435.477325 129.109116,433.917023 
	C128.739426,432.489136 128.175613,431.111481 127.697350,429.711700 
	C126.924095,429.922546 126.150848,430.133423 125.377602,430.344269 
	C126.499634,435.328888 127.597549,440.319489 128.828522,445.277069 
	C128.879852,445.483826 130.173569,445.382111 131.348755,444.831238 
M175.001755,433.760895 
	C175.919464,437.900970 176.837173,442.041077 177.754883,446.181152 
	C178.368347,446.148621 178.981796,446.116089 179.595245,446.083557 
	C180.878113,443.401459 182.160980,440.719391 183.843185,437.202423 
	C184.531219,439.868683 184.780640,441.680878 185.491669,443.288971 
	C185.954498,444.335663 187.089035,445.085388 187.922653,445.968170 
	C188.659134,445.154114 189.718430,444.459473 190.071976,443.503723 
	C191.163498,440.553070 192.121887,437.535400 192.853149,434.477081 
	C193.199753,433.027588 192.912659,431.426605 192.912659,429.894257 
	C192.373627,429.835022 191.834610,429.775787 191.295578,429.716583 
	C190.295029,432.915619 189.294464,436.114655 188.293900,439.313721 
	C187.143005,437.925293 187.118805,436.730621 186.691895,435.703339 
	C185.873993,433.735260 184.827271,431.862305 183.875519,429.949860 
	C182.843689,431.757782 181.825882,433.574036 180.761902,435.362823 
	C180.631805,435.581573 180.254135,435.653046 179.584808,436.008698 
	C179.051941,434.682190 178.655518,433.322968 177.974030,432.126038 
	C177.497238,431.288605 176.630890,430.672974 175.938538,429.958282 
	C175.608627,430.960236 175.278717,431.962158 175.001755,433.760895 
M155.509491,445.255585 
	C156.646881,442.822113 157.784271,440.388611 159.116638,437.537964 
	C160.344833,440.947418 161.328354,443.677704 162.311874,446.407990 
	C163.073334,446.392670 163.834793,446.377350 164.596252,446.362030 
	C166.185928,441.054138 167.775604,435.746216 169.365295,430.438293 
	C168.693680,430.175293 168.022064,429.912262 167.350449,429.649261 
	C166.087234,432.289581 164.824020,434.929932 163.217728,438.287384 
	C162.064514,434.870453 161.190216,432.279907 160.315903,429.689331 
	C159.874222,429.698242 159.432526,429.707153 158.990829,429.716064 
	C157.773361,432.480347 156.555908,435.244659 154.935867,438.923035 
	C153.907089,434.981903 153.218689,432.344757 152.268951,428.706421 
	C149.855179,433.492889 151.368439,443.283997 154.117233,445.846954 
	C154.250015,445.970734 154.712219,445.741150 155.509491,445.255585 
M212.463211,423.569427 
	C213.153717,430.772644 211.350342,438.151184 212.572067,445.317078 
	C213.073120,445.303162 213.574173,445.289276 214.075211,445.275360 
	C215.041626,442.882629 216.008026,440.489868 217.335327,437.203613 
	C220.089478,440.860870 221.931244,443.306580 223.773010,445.752258 
	C224.354187,445.472687 224.935379,445.193115 225.516556,444.913544 
	C223.980881,441.787048 222.445221,438.660583 221.005859,435.730164 
	C222.449295,433.643585 223.805725,431.682800 225.162140,429.722015 
	C224.712173,429.318909 224.262192,428.915802 223.812225,428.512665 
	C221.352921,430.509186 218.893600,432.505707 215.554642,435.216370 
	C215.554642,430.700165 215.690613,427.562714 215.512680,424.443176 
	C215.363922,421.835419 215.201279,421.844696 212.463211,423.569427 
M313.647186,431.904175 
	C310.689301,430.942535 307.731415,429.980896 304.161713,428.820374 
	C304.076141,427.286407 303.929321,424.654236 303.782532,422.022064 
	C303.100098,422.026917 302.417664,422.031769 301.735229,422.036621 
	C300.429199,429.564056 301.404419,437.219025 301.337463,444.793274 
	C313.975250,446.092926 317.854553,442.194458 313.647186,431.904175 
M262.443115,441.769806 
	C262.304077,442.591949 262.165070,443.414062 262.026031,444.236206 
	C265.280151,444.416565 268.889099,445.559692 271.695587,444.525421 
	C274.958160,443.323090 274.187561,440.056396 272.219482,437.454712 
	C271.474670,436.470062 270.952881,435.316681 271.058197,435.498505 
	C272.313660,433.184906 274.010437,431.527130 273.796234,430.171295 
	C273.206573,426.438812 270.576080,428.864136 268.767700,429.145538 
	C267.645355,429.320190 266.237213,429.563293 265.566162,430.317383 
	C264.353790,431.679810 263.075653,433.383698 262.867279,435.073547 
	C262.760559,435.938934 264.807373,437.235962 266.063324,438.059723 
	C267.516602,439.012909 269.170441,439.660278 270.737183,440.440399 
	C270.558868,441.136688 270.380524,441.832947 270.202179,442.529236 
	C267.859894,442.282501 265.517609,442.035767 262.443115,441.769806 
M237.244888,432.641785 
	C239.028290,432.743652 240.811691,432.845551 243.249146,432.984772 
	C243.097229,431.573975 242.923370,429.959381 242.680771,427.706299 
	C239.391891,428.826935 236.600662,429.426941 234.205582,430.746185 
	C233.055008,431.379974 231.986755,433.434509 232.107651,434.731262 
	C232.218338,435.918701 233.828766,437.178223 235.052429,437.995758 
	C236.549484,438.995972 238.332047,439.568878 239.991180,440.326538 
	C239.885208,440.870972 239.779236,441.415436 239.673279,441.959900 
	C236.753296,441.959900 233.833328,441.959900 230.616501,441.959900 
	C231.715149,446.403809 235.110809,446.371704 237.736649,445.727631 
	C239.727768,445.239288 242.521866,443.066254 242.677704,441.426178 
	C242.838654,439.732422 240.380356,437.645111 238.792480,435.994965 
	C238.070023,435.244110 236.684341,435.131470 235.599487,434.729340 
	C235.933899,434.141510 236.268326,433.553680 237.244888,432.641785 
M289.510101,445.209839 
	C290.565125,445.484009 291.620178,445.758148 293.281158,446.189758 
	C293.144470,440.329865 293.842468,434.918549 291.949707,429.608124 
	C290.644470,432.495361 290.334991,435.376404 290.328156,438.258148 
	C290.322449,440.667084 290.023254,443.043274 287.065674,442.433014 
	C285.828156,442.177673 284.378326,439.971069 284.142456,438.475372 
	C283.681824,435.554718 284.007294,432.510071 284.007294,429.516724 
	C283.427917,429.478668 282.848541,429.440582 282.269165,429.402496 
	C279.822510,432.295898 280.946106,435.999573 281.075287,439.332764 
	C281.290253,444.881073 283.011902,446.078644 289.510101,445.209839 
M365.893951,438.270599 
	C365.353638,431.811218 363.518524,429.484894 359.246429,429.843658 
	C355.019745,430.198608 352.369171,434.193085 352.817505,439.425690 
	C353.127838,443.047607 355.037903,445.533417 358.576752,445.827179 
	C363.112061,446.203674 364.810822,442.852539 365.893951,438.270599 
M344.905548,430.638641 
	C339.489990,428.512604 336.843201,429.510681 334.611481,434.520416 
	C332.624390,438.980988 333.562897,443.087738 337.013062,445.029114 
	C340.475769,446.977539 343.795715,446.011841 346.149506,442.037781 
	C345.177094,442.037781 344.435150,442.104492 343.709045,442.025360 
	C341.472321,441.781586 338.442383,442.232178 337.258026,440.970184 
	C336.286560,439.934998 337.146271,436.708344 337.855194,434.684570 
	C338.820435,431.929077 341.016724,431.677277 343.582062,432.888092 
	C343.874634,433.026184 344.826660,431.767059 344.905548,430.638641 
M251.061951,441.161469 
	C251.476196,442.467560 251.890442,443.773621 252.304688,445.079681 
	C252.717621,444.975006 253.130554,444.870331 253.543488,444.765656 
	C253.543488,439.875427 253.543488,434.985199 253.543488,430.094971 
	C252.941879,430.063110 252.340271,430.031281 251.738663,429.999420 
	C251.508774,433.417542 251.278885,436.835693 251.061951,441.161469 
z"/>
<path fill="#6BD0DD" opacity="1.000000" stroke="none" 
	d="
M428.989227,233.362946 
	C418.091919,235.496872 411.029144,231.537460 406.746887,221.657257 
	C406.149414,220.278717 404.048859,218.792175 402.557861,218.696274 
	C397.084930,218.344208 391.558838,218.798920 386.089661,218.418823 
	C384.329071,218.296463 382.109070,216.926392 381.099121,215.445297 
	C378.769379,212.028824 376.703308,208.333817 375.172424,204.495682 
	C373.126892,199.367203 370.101471,197.394104 364.324921,197.726166 
	C354.033691,198.317764 343.682678,197.994812 333.360138,197.833328 
	C330.512268,197.788757 328.440613,198.514572 327.501587,201.208466 
	C323.423889,212.906509 314.928528,215.136795 303.418701,214.445663 
	C286.991028,213.459274 270.459015,214.279266 253.974380,214.087814 
	C251.854568,214.063217 247.953293,216.101120 248.029343,211.578217 
	C248.043655,210.727875 251.808243,209.251114 253.853424,209.231598 
	C273.169678,209.047073 292.488983,209.035706 311.805725,209.187714 
	C315.158752,209.214096 317.668610,208.580551 318.915833,205.311340 
	C322.930115,194.789291 330.376221,191.476074 341.467102,192.730560 
	C351.004028,193.809296 360.762146,193.077225 370.417206,192.883652 
	C373.537048,192.821091 375.465729,193.760498 376.561005,196.675476 
	C377.083099,198.065002 378.171021,199.258881 378.598541,200.664291 
	C381.963684,211.726181 389.142517,215.873550 400.607849,214.004547 
	C404.113708,213.433060 405.990540,213.023987 407.361664,209.349472 
	C411.032623,199.511459 422.216003,195.344513 431.686462,199.746338 
	C440.911957,204.034286 445.214325,215.355698 439.955292,224.085526 
	C437.668518,227.881531 432.980469,230.230957 428.989227,233.362946 
M437.032318,216.405182 
	C436.279358,206.247360 428.098633,200.392776 419.281555,203.701736 
	C413.467255,205.883789 410.101685,211.790771 411.101471,218.058670 
	C412.079651,224.190918 417.318939,228.812912 423.439697,228.943054 
	C430.794312,229.099457 435.395416,225.123322 437.032318,216.405182 
z"/>
<path fill="#6ACFDD" opacity="1.000000" stroke="none" 
	d="
M266.001831,269.104980 
	C284.497772,269.105011 302.495026,269.198212 320.489929,269.031342 
	C324.373566,268.995331 327.286316,269.797485 329.103058,273.462524 
	C329.833984,274.937073 331.235260,276.093781 331.892059,277.588287 
	C336.278381,287.568909 343.750519,291.438171 354.448700,289.491425 
	C356.545532,289.109894 358.780731,289.305176 360.935608,289.461670 
	C366.750214,289.883820 371.299103,289.776489 373.830841,282.443634 
	C376.268799,275.382324 386.815979,272.132904 393.933502,274.417023 
	C402.784607,277.257507 407.771698,284.644135 407.057281,293.855377 
	C406.462097,301.529205 399.931396,308.578369 392.182068,309.911499 
	C383.869995,311.341431 375.032806,306.848114 372.471161,298.965179 
	C371.166901,294.951538 369.083771,294.418671 365.590302,294.502594 
	C357.762878,294.690674 349.927765,294.534698 342.097900,294.656555 
	C338.805664,294.707794 336.372192,293.786591 334.770111,290.655212 
	C333.643799,288.453674 331.591858,286.703064 330.586426,284.466431 
	C326.736603,275.902344 320.425720,273.589844 311.144806,273.962769 
	C291.513885,274.751587 271.828583,274.249512 252.167068,274.090851 
	C250.644348,274.078583 249.133392,272.608734 247.616943,271.816345 
	C249.079636,270.916168 250.490753,269.359314 252.014725,269.239655 
	C256.486542,268.888458 261.003235,269.108582 266.001831,269.104980 
M401.803528,294.852264 
	C401.858246,293.692780 402.009583,292.528503 401.952118,291.374603 
	C401.566345,283.628387 395.422821,278.364655 387.552948,278.992523 
	C380.526276,279.553131 375.432495,285.827362 376.026306,293.190399 
	C376.484802,298.875946 381.125336,304.070496 386.513336,304.929535 
	C393.426727,306.031738 398.177704,303.093384 401.803528,294.852264 
z"/>
<path fill="#6ACDDA" opacity="1.000000" stroke="none" 
	d="
M394.803650,150.686478 
	C398.192474,157.170975 399.774384,163.762878 395.790619,170.593643 
	C391.998077,177.096512 386.114502,180.328400 378.773926,179.895828 
	C371.386292,179.460449 365.782806,175.487961 363.274811,168.418732 
	C362.107941,165.129715 360.689117,164.109436 357.295898,164.144272 
	C340.645813,164.315231 323.991516,164.343994 307.342499,164.128769 
	C303.768494,164.082581 301.882996,165.420502 300.317871,168.347778 
	C298.044495,172.599625 295.288910,176.597458 293.086731,180.882339 
	C291.452423,184.062271 289.393219,185.393967 285.682251,185.312897 
	C274.364349,185.065582 263.036407,185.296432 251.716187,185.110489 
	C250.350220,185.088058 249.007507,183.651276 247.654007,182.870010 
	C249.009689,182.042831 250.351547,180.526230 251.723236,180.498749 
	C261.710205,180.298691 271.712952,180.140594 281.689758,180.519989 
	C286.174286,180.690552 288.755249,179.266571 290.661835,175.286118 
	C292.667328,171.099213 295.458557,167.293533 297.555298,163.143845 
	C299.113342,160.060318 301.164062,158.989731 304.636444,159.023209 
	C322.119110,159.191940 339.604675,159.039291 357.088440,159.138245 
	C360.212494,159.155930 362.002625,158.697327 363.301880,155.194000 
	C364.490845,151.987915 367.587921,149.489426 370.408813,146.342636 
	C371.237183,145.987030 371.500916,145.967941 371.871582,146.237854 
	C372.454620,147.683762 372.930725,148.840683 373.176025,150.270309 
	C366.551056,155.804306 365.124756,162.108734 368.904968,168.401550 
	C372.458679,174.317245 379.031555,176.517151 385.186493,173.850845 
	C393.693176,170.165741 395.284332,160.551727 389.137817,151.514862 
	C390.662994,150.712616 391.901123,150.105988 393.427246,149.684540 
	C394.078003,150.141953 394.440826,150.414215 394.803650,150.686478 
z"/>
<path fill="#52BFD7" opacity="1.000000" stroke="none" 
	d="
M185.048309,298.150330 
	C184.249084,298.402863 183.449844,298.655365 182.313812,298.668213 
	C181.977036,298.428558 181.947739,298.000397 182.049683,297.669403 
	C186.553894,294.684845 190.956177,292.031311 195.797195,289.113312 
	C195.732941,288.947418 195.217041,287.615356 194.955658,286.092499 
	C197.259872,284.672607 199.309540,283.443481 201.700287,282.163849 
	C210.501373,277.764984 217.459351,271.772797 223.812637,263.110870 
	C219.580856,263.110870 216.781555,263.132629 213.982742,263.104950 
	C210.989410,263.075348 207.996521,262.999115 205.126007,262.601257 
	C206.747025,262.072266 208.243362,261.746887 209.744308,261.724060 
	C214.385239,261.653534 219.027954,261.698303 224.355713,261.698303 
	C222.847290,258.634521 221.917770,256.746552 221.407074,254.759155 
	C224.093933,254.661041 226.361984,254.662354 228.926086,254.666107 
	C229.518753,254.201187 229.815353,253.733795 230.321915,252.968201 
	C231.682617,249.849762 232.966400,247.075256 233.954666,244.199234 
	C235.679413,239.180023 237.222244,234.098297 239.276245,229.029495 
	C243.157852,229.324280 245.502609,228.556122 245.092957,224.279953 
	C244.995239,223.259811 246.071259,222.127243 246.607422,221.046402 
	C246.558807,221.870789 246.510193,222.695175 246.258682,224.075226 
	C246.029510,224.870407 246.057373,225.103455 246.139374,225.330048 
	C245.723526,242.484955 240.015991,257.734802 229.332565,271.078888 
	C219.183609,283.755371 206.227371,292.363617 189.864929,296.943176 
	C187.749649,297.337128 186.398972,297.743713 185.048309,298.150330 
z"/>
<path fill="#69CEDB" opacity="1.000000" stroke="none" 
	d="
M254.006927,245.637451 
	C252.861954,245.697922 251.716980,245.758377 250.275055,245.313477 
	C250.318237,243.544861 250.658356,242.281631 250.998474,241.018387 
	C273.455902,240.941940 295.913361,240.864365 318.370789,240.790390 
	C321.359894,240.780548 324.349060,240.789017 327.452240,240.789017 
	C329.135040,234.407135 332.234009,229.365204 338.389618,226.636429 
	C347.696167,222.510864 358.011963,226.381058 362.181580,235.686691 
	C366.408539,245.120483 362.309357,255.854630 352.931335,259.909210 
	C343.738647,263.883667 333.078033,259.679657 329.108276,250.559723 
	C328.414795,248.966553 327.508942,247.465820 326.171356,245.459076 
	C324.415955,244.662201 323.191162,244.041290 321.964630,244.037857 
	C300.741180,243.978485 279.517303,243.963364 258.294159,244.065582 
	C256.862610,244.072464 255.435898,245.089981 254.006927,245.637451 
M334.995087,235.770462 
	C331.305206,242.660095 332.334351,249.168152 337.819794,253.633408 
	C342.337341,257.310791 349.409912,257.239471 353.893219,253.471283 
	C359.834259,248.477982 360.532349,240.168503 355.492157,234.439484 
	C350.167236,228.386826 342.121033,228.662674 334.995087,235.770462 
z"/>
<path fill="#175FA9" opacity="1.000000" stroke="none" 
	d="
M92.088943,209.104187 
	C93.459343,197.438461 98.216110,187.175888 104.827568,177.596893 
	C113.398193,165.179352 124.757042,156.338715 139.183426,150.211884 
	C141.476425,149.879532 143.167603,149.857239 144.960938,150.222092 
	C145.018112,153.407410 148.367233,156.245758 144.824036,158.829254 
	C144.482986,158.431641 144.258499,158.235504 143.934906,157.761032 
	C141.168045,155.068390 140.682281,157.481644 139.774841,159.472946 
	C139.420135,160.103607 139.200150,160.531494 138.659042,161.035309 
	C137.236313,161.774811 136.134735,162.438400 134.723328,163.139862 
	C133.612411,163.790955 132.811325,164.404175 131.686432,165.126678 
	C124.964371,169.034698 119.706932,174.016113 114.727348,180.530029 
	C117.761063,180.889664 119.839531,181.136063 121.801468,181.580719 
	C121.456551,182.186371 121.228157,182.593781 120.563339,183.000122 
	C117.077988,183.643921 112.392746,180.965347 111.636368,187.024796 
	C110.526482,188.069351 109.778351,189.090393 108.836258,190.391586 
	C103.459267,198.995361 100.419167,207.903320 100.870346,217.849472 
	C97.998604,216.584412 95.484962,215.330780 92.970200,213.695892 
	C92.675697,211.911148 92.382324,210.507660 92.088943,209.104187 
z"/>
<path fill="#3FB85B" opacity="1.000000" stroke="none" 
	d="
M308.912933,62.103527 
	C308.921600,62.499775 308.930267,62.896023 308.662415,63.626053 
	C308.041931,64.030357 307.698029,64.100868 307.129028,63.878609 
	C305.564270,62.358616 304.348389,60.951122 302.865479,59.932297 
	C289.283966,50.601112 274.869934,50.624107 260.258728,56.847176 
	C251.459549,60.594833 247.177521,68.117317 247.190811,77.385239 
	C247.203873,86.501778 250.850021,94.520706 259.746674,98.188004 
	C268.382935,101.747971 277.438690,104.366371 286.443512,106.930145 
	C292.808319,108.742264 296.464325,113.279205 295.957001,120.113647 
	C295.563324,125.417084 289.776123,130.658722 284.135345,131.002502 
	C273.413605,131.655960 263.628235,129.599258 255.961090,121.410721 
	C254.104996,119.428398 252.185364,119.016495 250.511734,121.152634 
	C248.027496,124.323425 244.907990,127.444069 246.551208,132.129517 
	C245.602600,131.712128 244.942596,131.318848 244.282593,130.925568 
	C244.083847,129.545517 243.215424,127.688507 243.800644,126.866814 
	C246.544418,123.014420 249.688095,119.446869 252.247375,116.317520 
	C258.579132,120.479286 263.909180,124.742393 269.881836,127.713684 
	C277.142365,131.325668 286.886566,129.843613 290.665680,124.901787 
	C292.385193,122.653259 293.766846,118.950172 293.128571,116.464935 
	C292.597015,114.395020 289.106750,112.412125 286.552490,111.656273 
	C277.672180,109.028473 268.115875,108.249603 260.528442,102.238365 
	C256.643127,99.160172 252.635315,95.974411 249.622574,92.105507 
	C240.043152,79.803894 245.898743,59.407303 260.590302,54.273586 
	C276.036041,48.876347 291.247345,48.915215 305.728638,59.086098 
	C306.391907,59.687363 306.682831,59.858376 306.973755,60.029388 
	C306.973755,60.029388 307.000000,59.999847 307.073639,60.248253 
	C307.420776,60.676502 307.694275,60.856346 307.967743,61.036194 
	C307.967743,61.036201 308.000000,61.000610 308.077759,61.244156 
	C308.345062,61.770370 308.597534,61.975647 308.912933,62.103527 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M199.579559,309.459167 
	C199.259079,305.165344 203.427307,302.076385 208.762131,301.966034 
	C211.717102,301.904907 214.650497,300.799835 218.046219,300.411743 
	C221.946396,305.485413 225.393951,310.312714 228.994522,315.354248 
	C230.489471,314.446320 231.723862,313.696625 232.958252,312.946960 
	C233.676453,318.382660 227.803665,316.385071 226.650803,318.766510 
	C225.542770,317.177429 224.500504,315.682678 223.458221,314.187927 
	C222.607376,315.502106 221.364731,316.708923 220.970276,318.148224 
	C218.682114,326.497559 212.240768,329.862640 204.105209,326.727539 
	C200.748077,325.433838 197.463562,324.488434 195.066132,328.307892 
	C192.991455,331.613159 189.971420,333.494080 186.213333,332.793976 
	C182.405197,332.084503 178.794968,330.368622 175.032684,329.330475 
	C173.456467,328.895569 171.711884,329.070679 169.520294,328.623047 
	C165.666611,327.734070 162.336487,327.190460 159.006378,326.646851 
	C158.950348,326.073395 158.894302,325.499939 158.838272,324.926483 
	C161.686218,324.746063 164.534164,324.565643 167.539276,324.375244 
	C167.539276,323.032288 167.539276,321.713043 167.539276,320.440979 
	C164.484756,320.123810 161.850433,319.850281 159.216125,319.576721 
	C159.225632,319.048737 159.235138,318.520782 159.244644,317.992798 
	C162.700165,317.992798 166.155701,317.992798 169.741287,317.992798 
	C169.741287,315.787445 169.741287,314.470886 169.741287,313.154327 
	C172.136612,313.075439 174.531952,312.996521 177.432800,312.932312 
	C177.938339,312.947021 178.063629,313.069672 177.890961,313.383728 
	C174.342789,318.316772 174.114075,323.981171 177.437531,327.853271 
	C180.766861,331.732269 185.061020,331.428741 189.332031,329.818726 
	C192.987137,328.440948 194.936005,325.905426 193.483398,320.421478 
	C191.397919,322.055664 189.990662,323.574280 188.253662,324.395508 
	C186.703705,325.128326 184.789398,325.090546 183.035858,325.392822 
	C182.684006,322.606384 182.332153,319.819916 181.980316,317.033478 
	C183.695953,317.016571 185.437454,316.806641 187.120636,317.032104 
	C189.179016,317.307770 191.188522,317.948456 194.268402,318.680847 
	C193.017532,315.850525 192.423676,314.506805 191.908264,313.086548 
	C191.986710,313.010010 192.142899,312.856201 192.142883,312.856201 
	C194.336990,311.733032 196.531097,310.609863 198.945587,309.947876 
	C199.103256,312.543549 198.620102,314.768372 199.055832,316.795715 
	C200.416077,323.124420 205.314926,325.823853 211.461166,323.961548 
	C217.666214,322.081421 220.585800,316.996399 218.693314,311.365295 
	C216.628448,305.221252 211.282700,302.767426 205.204056,305.478088 
	C203.146622,306.395599 201.444214,308.109283 199.579559,309.459167 
z"/>
<path fill="#11693B" opacity="1.000000" stroke="none" 
	d="
M315.789581,109.183167 
	C319.026764,119.439796 320.270752,129.561951 314.076385,139.305389 
	C309.264374,146.874496 301.741180,149.970184 293.466827,150.771774 
	C280.584198,152.019775 267.814453,151.339920 256.567108,143.669739 
	C254.563751,142.303543 252.903641,140.434036 251.065735,138.366043 
	C251.046463,137.935150 251.235718,137.711502 251.517059,137.799179 
	C252.186050,137.946365 252.573715,138.005875 252.985733,138.046875 
	C253.010086,138.028366 252.951279,138.041718 253.109467,138.317688 
	C254.184952,139.071884 255.102219,139.550125 256.290253,140.258743 
	C262.078094,141.952850 267.595184,143.416580 273.559204,144.939209 
	C278.138763,144.719620 282.271454,144.441162 286.730957,144.214050 
	C287.716705,144.226135 288.375580,144.186859 289.441711,144.138214 
	C297.030853,143.216415 303.087677,140.379974 307.150177,133.893036 
	C307.202850,133.743225 307.194641,133.425720 307.489014,133.261810 
	C308.759521,131.412704 309.735657,129.727493 310.810364,127.715073 
	C310.994995,126.940872 311.081024,126.493881 311.373779,125.725372 
	C311.461395,118.901749 311.342316,112.399658 311.538574,105.971954 
	C313.165802,107.091957 314.477692,108.137558 315.789581,109.183167 
z"/>
<path fill="#256FAD" opacity="1.000000" stroke="none" 
	d="
M202.020157,151.810760 
	C202.035889,151.995499 202.051636,152.180252 201.690186,152.637711 
	C198.056473,152.604614 194.803421,152.088028 191.542740,152.034363 
	C183.276093,151.898254 183.274719,151.981384 181.603180,160.040588 
	C178.922195,158.291626 176.549973,156.693207 173.533844,154.660919 
	C173.182251,159.608948 172.915039,163.369507 172.236938,167.065018 
	C168.323380,165.697342 167.237167,167.051437 166.866898,170.622391 
	C166.501358,174.147903 165.091202,177.565094 164.150208,180.559509 
	C164.124435,171.655640 164.087769,163.222107 164.042999,154.395386 
	C162.912399,148.327454 168.767639,149.199707 172.393570,145.945068 
	C167.273865,145.945068 163.579361,145.945068 160.171921,145.657791 
	C166.953720,145.370132 173.448456,145.369751 180.255020,145.589371 
	C187.717957,147.809830 194.869064,149.810303 202.020157,151.810760 
z"/>
<path fill="#3595C4" opacity="1.000000" stroke="none" 
	d="
M229.873703,175.021881 
	C230.148575,175.213135 230.423447,175.404388 230.717865,175.885773 
	C230.737411,176.175903 230.731873,176.369827 230.731873,176.369827 
	C230.731873,176.369827 230.920563,176.135223 230.920563,176.135223 
	C231.567154,176.891327 232.213745,177.647430 232.703979,178.964600 
	C233.296127,183.097153 234.044632,186.668671 234.891479,190.709442 
	C232.733276,190.476471 231.298843,190.321625 229.887695,190.138336 
	C229.910980,190.109909 229.918396,190.181793 229.817871,189.792862 
	C225.566345,187.111359 219.697281,187.426178 216.541840,190.184586 
	C213.743729,190.208038 211.379669,190.134293 208.981934,189.852631 
	C208.807480,189.429794 208.666687,189.214890 208.525909,188.999969 
	C208.349930,189.336334 208.173935,189.672684 207.791473,190.221893 
	C207.318375,190.960815 207.051743,191.486893 206.506775,192.141510 
	C206.151489,197.839386 206.074554,203.408752 205.662933,208.981049 
	C204.882004,208.985931 204.435776,208.987869 203.753540,208.711349 
	C202.345200,203.289215 201.172882,198.145523 200.382980,192.995361 
	C204.846939,191.608627 207.035980,186.376633 204.976974,182.694824 
	C205.012924,182.057373 205.030090,181.692902 205.508087,181.270111 
	C211.416565,181.211792 216.864197,181.211792 223.558075,181.211792 
	C221.407028,178.426468 220.156143,176.806747 219.003052,174.843185 
	C221.166962,172.886719 223.233093,171.274094 225.299210,169.661453 
	C225.299210,169.661453 225.143875,169.715118 225.205688,170.058044 
	C225.941895,170.837097 226.616287,171.273239 227.290665,171.709396 
	C227.290665,171.709396 227.005707,171.949234 227.130768,172.166992 
	C227.861130,172.908279 228.466415,173.431793 229.071686,173.955322 
	C229.071686,173.955322 229.011932,173.992706 229.103836,174.183853 
	C229.353912,174.661713 229.579895,174.877335 229.873703,175.021881 
z"/>
<path fill="#EAF2F5" opacity="1.000000" stroke="none" 
	d="
M232.977356,312.967468 
	C231.723862,313.696625 230.489471,314.446320 228.994522,315.354248 
	C225.393951,310.312714 221.946396,305.485413 218.405701,300.348999 
	C220.567551,298.424866 222.822510,296.809845 225.093567,295.183289 
	C227.619385,297.201385 229.842850,298.977905 232.066330,300.754456 
	C232.319107,300.553802 232.571899,300.353149 232.824677,300.152496 
	C231.888107,297.428070 230.951523,294.703644 229.911621,291.678650 
	C232.031647,290.158905 234.148376,288.641510 236.583908,286.895599 
	C239.794037,291.437897 242.749954,295.992828 246.103333,300.233429 
	C248.307358,303.020599 245.681793,303.475922 243.880524,305.005249 
	C241.589798,303.375885 239.917053,301.466248 237.982330,299.424377 
	C237.645447,299.572113 237.570541,299.852081 237.495667,300.132050 
	C237.757492,299.887329 238.019333,299.642609 237.832596,299.599884 
	C238.228394,302.415680 239.072754,305.029449 240.314606,308.873596 
	C237.033783,308.873596 234.383484,308.873596 231.616608,308.873596 
	C232.013412,310.093506 232.480209,311.528503 232.946991,312.963531 
	C232.946991,312.963531 232.996460,312.988007 232.977356,312.967468 
M230.810501,308.187744 
	C231.157394,308.108185 231.504288,308.028656 231.851166,307.949097 
	C231.786682,307.676422 231.722198,307.403778 231.657730,307.131134 
	C231.174927,307.290070 230.692123,307.449005 230.810501,308.187744 
z"/>
<path fill="#24192E" opacity="1.000000" stroke="none" 
	d="
M202.299850,151.613983 
	C194.869064,149.810303 187.717957,147.809830 180.145187,145.241455 
	C171.864120,135.605362 164.004745,126.537170 156.181198,117.510315 
	C155.445526,127.428719 154.728012,137.102341 153.900269,146.854156 
	C153.790039,146.932343 153.522766,146.972565 153.357346,146.599182 
	C153.127548,136.093079 152.965271,125.958824 153.122253,115.829521 
	C153.141937,114.558670 154.845306,113.313904 155.989929,112.298668 
	C157.424759,113.738762 158.638229,114.937241 159.955627,116.459076 
	C165.680267,123.737648 171.652954,130.444382 176.799149,137.734558 
	C180.287277,142.675903 184.606705,145.604065 190.327454,145.310913 
	C194.390533,145.102722 199.228592,148.086945 203.050583,143.137283 
	C199.592285,138.415756 196.201553,133.786484 192.924622,129.061478 
	C193.038422,128.965744 193.269608,128.778671 193.269608,128.778671 
	C196.632477,128.635101 198.617020,130.096939 200.205399,133.230911 
	C202.363831,137.489594 205.449539,141.268707 207.899323,145.394943 
	C208.679520,146.709045 209.251328,148.634003 208.862366,149.972580 
	C208.628662,150.776901 206.378036,151.080399 204.988754,151.429642 
	C204.234406,151.619308 203.385696,151.433578 202.299850,151.613983 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M127.635742,288.070648 
	C127.395409,287.983154 127.155083,287.895691 126.991455,287.367065 
	C128.107407,284.385345 129.146683,281.844818 130.525772,279.454163 
	C135.197266,281.815918 139.528946,284.027863 144.892807,286.766846 
	C142.179153,281.283722 140.129272,277.141785 138.459152,273.002838 
	C140.431015,273.005829 142.023102,273.005829 143.925644,273.005829 
	C143.388840,269.714813 142.892227,266.755096 142.426910,263.790466 
	C142.061829,261.464264 142.635559,260.435730 144.990021,262.224945 
	C144.987823,262.433014 144.995102,262.849121 144.769531,263.109314 
	C144.856796,264.922119 144.843964,266.640869 145.537140,267.999420 
	C149.121109,275.023651 151.740585,282.687622 157.838562,288.052673 
	C153.805588,289.398773 150.082382,290.546692 148.589218,294.988617 
	C145.786392,294.467285 143.319061,294.159760 141.006165,293.388916 
	C137.009277,292.056793 133.108276,290.436981 129.167038,288.937866 
	C129.167038,288.937866 129.052872,288.986877 128.885605,288.783386 
	C128.357468,288.410126 127.996605,288.240387 127.635742,288.070648 
z"/>
<path fill="#24192E" opacity="1.000000" stroke="none" 
	d="
M136.163254,149.605682 
	C129.217560,148.022263 125.041359,143.111252 125.040886,136.505096 
	C125.039223,113.433327 124.917244,90.360115 125.181091,67.291351 
	C125.219810,63.905380 127.100624,60.540474 128.519348,57.500454 
	C128.937378,83.442604 128.946075,109.050171 129.028214,134.657516 
	C129.034973,136.763260 129.540665,138.867416 129.897614,141.354523 
	C131.628448,142.573090 133.276184,143.409454 135.085663,144.559662 
	C135.552658,146.450882 135.857956,148.028290 136.163254,149.605682 
z"/>
<path fill="#49AECE" opacity="1.000000" stroke="none" 
	d="
M246.667099,220.624481 
	C246.071259,222.127243 244.995239,223.259811 245.092957,224.279953 
	C245.502609,228.556122 243.157852,229.324280 238.936554,228.854614 
	C236.359985,228.141830 234.571823,227.173126 232.753586,227.112900 
	C225.514938,226.873123 218.266876,226.916534 211.061203,226.627304 
	C211.731720,226.261307 212.363342,226.123077 213.335815,225.972244 
	C214.450760,225.306000 215.224838,224.652390 216.032928,223.692261 
	C214.698898,221.926117 213.330872,220.466476 211.977722,218.774567 
	C211.992599,218.542313 212.016846,218.077438 212.479111,218.001160 
	C220.848099,217.924866 228.754822,217.924866 237.422272,217.924866 
	C236.597549,212.502258 235.930710,208.117767 235.585388,203.642990 
	C236.838333,204.179749 237.769760,204.806808 237.959991,204.934875 
	C240.203613,204.201126 242.033478,203.602692 243.863342,203.004272 
	C244.817825,208.737045 245.772293,214.469803 246.667099,220.624481 
M220.065491,221.505066 
	C220.865021,222.940262 221.664551,224.375458 222.464096,225.810638 
	C224.955688,224.172546 227.447296,222.534454 229.938889,220.896362 
	C229.591873,220.110031 229.244858,219.323715 228.897842,218.537384 
	C225.998947,219.259872 223.100052,219.982361 220.065491,221.505066 
z"/>
<path fill="#24192E" opacity="1.000000" stroke="none" 
	d="
M128.818802,54.005341 
	C128.531555,50.058659 131.697662,49.608044 134.239243,49.276482 
	C137.112946,48.901588 140.150467,49.778351 143.023087,49.399925 
	C147.024673,48.872780 149.765991,49.805218 150.970078,53.878708 
	C151.015839,54.033508 151.102951,54.247868 151.226151,54.297775 
	C161.315201,58.384838 154.296844,66.500549 155.508484,72.668304 
	C155.733276,73.812706 155.369324,75.072769 155.277115,76.279457 
	C155.718674,76.509735 156.160233,76.740013 156.601791,76.970291 
	C160.250992,72.634209 163.972382,68.356277 167.526855,63.943890 
	C170.439362,60.328396 172.841873,56.250023 176.055740,52.946205 
	C178.524765,50.408112 181.572723,48.129612 185.871567,49.659489 
	C187.439117,50.217354 189.461899,49.667065 191.247116,49.412651 
	C199.011353,48.306168 202.200027,50.458241 204.030838,58.568272 
	C204.027618,58.973118 203.973282,59.024265 203.555374,59.003365 
	C201.461548,58.981701 199.785614,58.980934 198.055405,58.583076 
	C198.261932,56.477184 198.522766,54.768387 198.877182,52.446449 
	C197.270630,52.446449 195.955566,52.446449 194.416016,52.458889 
	C193.891129,52.452679 193.596100,52.402405 192.833191,52.197922 
	C189.582199,52.026493 186.802887,52.020741 184.027191,51.917698 
	C180.701553,51.794243 178.342270,53.063885 177.066986,56.604259 
	C170.249695,64.925743 163.632828,72.982735 156.789917,81.192139 
	C156.331009,81.819962 156.098145,82.295372 155.684067,82.991173 
	C155.083282,83.595131 154.663727,83.978706 153.984741,83.996475 
	C153.459290,77.585373 152.931946,71.536232 153.021042,65.496170 
	C153.078445,61.604595 152.395966,58.740685 148.144958,57.397865 
	C147.340744,55.704834 146.579865,53.304047 145.804062,53.299217 
	C140.463135,53.265949 135.119095,53.733330 129.536926,54.025734 
	C129.297806,54.011803 128.818802,54.005341 128.818802,54.005341 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M91.001892,288.997284 
	C91.498596,288.169708 92.185555,287.400055 92.458290,286.504303 
	C93.510246,283.049316 96.193169,282.609070 99.036690,283.179535 
	C102.113289,283.796753 102.760277,285.961212 101.075226,288.927673 
	C99.280243,288.253784 97.935242,287.395996 96.528191,286.498657 
	C95.514961,287.846405 94.742188,288.874298 93.941742,289.938995 
	C95.589699,291.455750 96.981789,292.736969 98.419579,294.060272 
	C95.604858,297.959442 93.023880,301.534821 90.325912,305.272247 
	C92.117493,306.520782 93.444252,307.445404 95.112709,308.608154 
	C97.985443,304.897797 100.696747,301.395935 103.527931,297.739258 
	C105.262230,298.866638 106.874771,299.914886 108.689774,301.094727 
	C109.604790,299.707855 110.379631,298.533447 111.196129,297.295898 
	C107.784904,294.624695 104.656898,292.175262 101.528900,289.725830 
	C102.959114,289.736267 104.838089,289.162537 105.742523,289.857330 
	C109.852371,293.014801 113.687691,296.529602 117.246826,300.128937 
	C116.019943,300.512390 114.637009,300.416534 114.397614,300.894012 
	C111.802376,306.070221 109.386642,311.336456 106.952209,316.513702 
	C110.060333,318.169800 112.636330,319.542389 115.212326,320.914948 
	C114.880165,320.934662 114.547874,320.973541 114.215851,320.971344 
	C103.379135,320.899445 104.359261,317.151093 106.912300,309.480377 
	C108.048775,306.065765 107.912758,304.277588 104.886536,302.436401 
	C99.685684,305.161224 98.453423,314.887421 88.857025,310.645752 
	C89.183105,307.531586 89.557388,303.992462 89.923309,300.452484 
	C90.290070,296.904510 90.648476,293.355682 91.005272,289.403625 
	C91.000000,289.000000 91.001900,288.997284 91.001892,288.997284 
z"/>
<path fill="#2C8DC4" opacity="1.000000" stroke="none" 
	d="
M98.234161,254.929993 
	C97.804001,254.183289 97.373848,253.436600 97.276398,252.352478 
	C99.145157,250.890350 100.681206,249.765671 102.265175,248.605911 
	C103.292778,249.280655 104.618065,250.150879 106.185928,251.298569 
	C107.966896,252.519806 109.459343,254.178650 111.051811,254.280991 
	C117.257751,254.679810 123.495399,254.585312 130.029327,254.789368 
	C130.552704,254.945923 130.767975,254.987427 130.989105,255.392059 
	C131.100723,257.243958 131.206451,258.732727 131.305542,260.128052 
	C125.835541,257.549469 122.189224,261.443359 117.707344,263.010254 
	C116.322441,263.598267 115.265572,264.109222 114.163269,265.033813 
	C112.692406,267.987671 111.266968,270.527863 109.841522,273.068054 
	C106.516968,268.288971 103.192413,263.509888 100.090012,258.500702 
	C101.267082,258.674438 102.222008,259.078278 103.176926,259.482117 
	C103.367424,259.074951 103.557915,258.667786 103.748413,258.260590 
	C101.910332,257.150391 100.072243,256.040192 98.234161,254.929993 
z"/>
<path fill="#1F2E08" opacity="1.000000" stroke="none" 
	d="
M371.764648,145.948868 
	C371.500916,145.967941 371.237183,145.987030 370.580383,146.048065 
	C366.931793,144.520508 366.023132,141.895325 366.035889,138.371994 
	C366.132324,111.725700 366.050201,85.078804 366.118622,58.432335 
	C366.124878,55.986641 366.793152,53.542652 367.302795,50.090164 
	C372.882782,50.090164 378.294678,50.021252 383.701660,50.154144 
	C384.666748,50.177868 385.611298,51.037701 386.150024,51.954468 
	C380.295502,52.397465 374.856232,52.397465 368.936829,52.397465 
	C368.936829,54.567204 368.936890,56.184563 368.936829,57.801926 
	C368.935822,84.681892 369.009369,111.562317 368.842285,138.441254 
	C368.820709,141.917053 369.712372,143.973633 372.895813,144.916031 
	C372.392303,145.346161 372.078461,145.647522 371.764648,145.948868 
z"/>
<path fill="#672D30" opacity="1.000000" stroke="none" 
	d="
M136.507431,149.666092 
	C135.857956,148.028290 135.552658,146.450882 135.549683,144.491913 
	C137.849487,144.103745 139.877106,143.872589 141.838531,144.135391 
	C146.525726,144.763428 148.348618,143.141724 148.184921,138.181610 
	C147.833725,127.539909 148.017822,116.880531 148.181732,105.960403 
	C149.222244,105.550575 150.063736,105.408745 150.979584,105.588837 
	C152.625458,107.959518 154.197021,110.008286 155.768570,112.057053 
	C154.845306,113.313904 153.141937,114.558670 153.122253,115.829521 
	C152.965271,125.958824 153.127548,136.093079 153.129669,146.832642 
	C150.331192,148.237976 147.594986,149.036469 144.858780,149.834961 
	C143.167603,149.857239 141.476425,149.879532 139.358902,149.910645 
	C138.238907,149.855164 137.545258,149.790833 136.507431,149.666092 
z"/>
<path fill="#EAF2F5" opacity="1.000000" stroke="none" 
	d="
M115.482498,320.937317 
	C112.636330,319.542389 110.060333,318.169800 106.952209,316.513702 
	C109.386642,311.336456 111.802376,306.070221 114.397614,300.894012 
	C114.637009,300.416534 116.019943,300.512390 117.211800,300.493958 
	C117.551720,300.645874 117.600342,300.711975 117.917694,300.877747 
	C121.417496,302.452545 124.599930,303.861542 127.782364,305.270569 
	C127.782364,305.270569 127.908875,305.085785 128.053482,305.447906 
	C128.322922,307.051208 128.447769,308.292419 128.572601,309.533600 
	C128.572601,309.533600 128.615845,309.626190 128.263977,309.797546 
	C125.988647,309.968933 124.065178,309.968933 121.119461,309.968933 
	C122.953972,311.342865 123.928009,312.072357 125.901306,313.550232 
	C123.407501,314.781860 121.426758,315.760101 119.210442,316.854675 
	C120.459267,317.796722 121.814270,318.818848 123.483772,320.078247 
	C122.893204,321.153137 122.252434,322.319458 121.649185,323.417419 
	C119.836151,322.796021 118.451286,322.321350 117.066406,321.846710 
	C116.628494,321.551025 116.190582,321.255341 115.482498,320.937317 
z"/>
<path fill="#257DBC" opacity="1.000000" stroke="none" 
	d="
M181.911911,160.191116 
	C183.274719,151.981384 183.276093,151.898254 191.542740,152.034363 
	C194.803421,152.088028 198.056473,152.604614 201.746933,152.909973 
	C202.180862,152.909500 202.596405,152.987350 202.867249,153.387970 
	C204.477341,154.899399 205.926758,155.904007 207.126862,157.148987 
	C208.503281,158.576874 209.658600,160.217911 211.038116,161.924896 
	C205.650269,160.889603 201.243271,160.042770 196.858627,159.149994 
	C196.880981,159.104065 196.978256,159.135391 196.739655,158.882202 
	C195.833755,158.456589 195.166458,158.284195 194.263794,158.249664 
	C194.375717,159.917908 194.723022,161.448273 194.771561,163.055176 
	C194.984268,165.416168 195.495758,167.700638 195.789307,170.200348 
	C195.042389,171.273895 194.513397,172.132202 193.829239,172.716232 
	C192.405334,172.025909 191.136612,171.609848 189.627014,170.950439 
	C186.894730,167.201767 184.403320,163.696442 181.911911,160.191116 
z"/>
<path fill="#1F73B9" opacity="1.000000" stroke="none" 
	d="
M92.971329,214.077148 
	C95.484962,215.330780 97.998604,216.584412 101.265228,217.855499 
	C103.349754,217.936508 104.681274,218.000046 106.116348,218.176239 
	C106.219894,218.288895 106.646172,218.338318 106.646172,218.338318 
	C106.646172,218.338318 106.989029,218.053391 107.403870,217.995514 
	C109.211540,217.976410 110.604370,218.015182 112.052139,218.393814 
	C112.601997,223.756729 110.830795,225.677124 106.019051,224.721649 
	C102.647041,224.052048 100.091949,224.239044 98.715118,228.086639 
	C98.851532,230.642334 99.615723,233.124390 99.577263,235.593948 
	C99.536079,238.237747 98.738808,240.869751 98.271538,243.506912 
	C97.534866,243.498978 96.798195,243.491058 96.061531,243.483124 
	C95.037971,239.124069 94.068497,234.751266 92.965126,230.412506 
	C92.501976,228.591293 91.755432,226.842133 91.137726,225.060226 
	C91.078598,223.964584 91.019470,222.868927 91.293900,221.310074 
	C91.480324,220.243652 91.333191,219.640427 91.186066,219.037216 
	C91.139786,218.851685 91.093506,218.666138 91.063568,217.998947 
	C91.079918,217.517303 91.077065,217.125031 91.077065,217.125031 
	C91.077065,217.125031 91.116806,217.015503 91.461281,216.911713 
	C92.194283,215.897644 92.582809,214.987396 92.971329,214.077148 
z"/>
<path fill="#EAF2F5" opacity="1.000000" stroke="none" 
	d="
M169.445129,313.152527 
	C169.741287,314.470886 169.741287,315.787445 169.741287,317.992798 
	C166.155701,317.992798 162.700165,317.992798 159.244644,317.992798 
	C159.235138,318.520782 159.225632,319.048737 159.216125,319.576721 
	C161.850433,319.850281 164.484756,320.123810 167.539276,320.440979 
	C167.539276,321.713043 167.539276,323.032288 167.539276,324.375244 
	C164.534164,324.565643 161.686218,324.746063 158.838272,324.926483 
	C158.894302,325.499939 158.950348,326.073395 159.006378,326.646851 
	C162.336487,327.190460 165.666611,327.734070 169.205078,328.638855 
	C169.307510,329.746613 169.201584,330.493225 168.585693,331.663208 
	C163.018433,331.844635 157.961121,331.602692 152.903809,331.360779 
	C152.840195,331.131287 152.776566,330.901794 152.708984,329.909912 
	C152.800354,325.098206 152.895676,321.048889 152.990997,316.999573 
	C153.106110,316.066620 153.221222,315.133636 153.644775,313.582031 
	C158.634354,312.944000 163.315491,312.924561 167.996628,312.905151 
	C168.380737,312.987030 168.764847,313.068878 169.445129,313.152527 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M152.651672,317.117798 
	C152.895676,321.048889 152.800354,325.098206 152.549866,329.590088 
	C151.043701,328.857971 149.692719,327.683319 147.986816,326.200104 
	C147.850342,327.201080 148.072174,328.543579 147.511871,329.103546 
	C146.071121,330.543518 143.901917,332.971222 142.703506,332.628357 
	C138.402847,331.398071 134.383621,329.184052 130.626282,327.015686 
	C135.596268,327.785583 140.197983,328.881653 145.101746,330.049683 
	C145.450195,328.301270 145.721649,326.939209 145.994537,325.569916 
	C142.703354,324.579407 139.803574,323.706726 136.885300,322.828461 
	C138.068436,318.099609 139.104553,313.958374 140.228119,309.467682 
	C138.217026,308.918365 136.419937,308.427490 134.505997,307.904694 
	C132.869461,313.909821 131.355591,319.464844 129.841721,325.019867 
	C129.907700,322.600861 130.105560,320.176300 130.004181,317.764343 
	C129.892853,315.115540 129.505829,312.478271 128.905762,309.684814 
	C128.447769,308.292419 128.322922,307.051208 127.973297,305.526062 
	C133.059128,305.623871 138.369751,306.005646 143.295761,306.359741 
	C142.150391,310.983246 141.050430,315.423401 139.819916,320.390594 
	C143.535843,320.877594 146.572601,321.275604 149.898392,321.711487 
	C150.690063,320.243744 151.501190,318.739899 152.651672,317.117798 
z"/>
<path fill="#11693B" opacity="1.000000" stroke="none" 
	d="
M269.994537,78.849106 
	C269.923462,79.314735 269.852386,79.780365 269.568665,80.906540 
	C269.094299,82.505516 268.832520,83.443939 268.262939,84.322403 
	C266.992767,83.822968 266.030426,83.383499 264.998444,82.659065 
	C264.588104,81.912422 264.247375,81.450737 263.870300,80.614029 
	C262.259430,73.136269 263.916870,70.696907 273.320679,66.883347 
	C274.492065,66.857727 275.272583,66.800682 276.477661,66.803917 
	C278.895142,66.856697 280.888031,66.849197 283.256409,66.897781 
	C284.641235,67.021866 285.650543,67.089867 286.903625,67.360374 
	C287.748657,67.717545 288.349945,67.872208 289.197327,68.259415 
	C292.349396,70.623863 295.255341,72.755753 297.753448,74.902611 
	C291.336029,74.270065 285.337524,73.294510 279.312897,73.090233 
	C275.288300,72.953766 271.416718,74.216583 269.994537,78.849106 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M376.128967,445.014709 
	C375.186859,444.799805 373.430115,444.610016 373.422607,444.366241 
	C373.263977,439.226318 373.302612,434.080322 373.302612,428.935516 
	C373.750122,428.879974 374.197662,428.824402 374.645172,428.768829 
	C375.326080,429.545349 376.006958,430.321869 376.319427,430.678192 
	C380.688904,430.351471 384.842804,429.952332 389.005524,429.808960 
	C389.780060,429.782288 391.165405,430.673248 391.291687,431.328888 
	C392.218933,436.143005 392.897461,441.005035 393.654358,445.851929 
	C392.828552,445.995300 392.002777,446.138672 391.176971,446.282013 
	C390.339325,441.869232 389.501709,437.456421 388.653503,432.988037 
	C384.895996,433.169250 383.867218,435.029510 384.196198,437.965454 
	C384.417877,439.943909 384.980499,441.938080 384.826691,443.880554 
	C384.755951,444.773895 383.332550,445.560150 382.243591,445.766327 
	C381.617279,441.158905 381.269867,437.180115 380.875427,432.662964 
	C375.048981,435.795898 376.050659,440.570740 376.128967,445.014709 
z"/>
<path fill="#3595C4" opacity="1.000000" stroke="none" 
	d="
M109.949402,273.388550 
	C111.266968,270.527863 112.692406,267.987671 114.452805,265.214172 
	C119.920494,269.755341 125.053230,274.529816 130.185959,279.304291 
	C129.146683,281.844818 128.107407,284.385345 126.687881,287.132507 
	C120.890831,282.795807 115.474052,278.252441 109.949402,273.388550 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M131.118622,445.127686 
	C130.173569,445.382111 128.879852,445.483826 128.828522,445.277069 
	C127.597549,440.319489 126.499634,435.328888 125.377602,430.344269 
	C126.150848,430.133423 126.924095,429.922546 127.697350,429.711700 
	C128.175613,431.111481 128.739426,432.489136 129.109116,433.917023 
	C129.513062,435.477325 129.737411,437.084106 130.290192,439.981995 
	C131.978745,435.762054 133.196640,432.718353 134.906113,428.446075 
	C136.670578,431.808746 137.780579,433.924133 139.102188,436.442810 
	C140.548706,433.676117 141.642288,431.584473 142.735870,429.492828 
	C143.463623,429.825958 144.191376,430.159058 144.919128,430.492157 
	C143.339066,435.789978 141.758987,441.087769 140.178925,446.385590 
	C139.437225,446.407959 138.695541,446.430328 137.953842,446.452698 
	C136.923035,443.735107 135.892212,441.017487 134.572601,437.538574 
	C133.353317,440.296722 132.351044,442.563965 131.118622,445.127686 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M196.836273,159.195938 
	C201.243271,160.042770 205.650269,160.889603 211.038116,161.924896 
	C209.658600,160.217911 208.503281,158.576874 207.126862,157.148987 
	C205.926758,155.904007 204.477341,154.899399 203.067139,153.456711 
	C211.878433,156.332901 218.877090,162.162704 225.215576,169.344650 
	C223.233093,171.274094 221.166962,172.886719 218.733612,174.641586 
	C211.189667,169.587845 204.012970,164.391891 196.836273,159.195938 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M174.975281,433.362488 
	C175.278717,431.962158 175.608627,430.960236 175.938538,429.958282 
	C176.630890,430.672974 177.497238,431.288605 177.974030,432.126038 
	C178.655518,433.322968 179.051941,434.682190 179.584808,436.008698 
	C180.254135,435.653046 180.631805,435.581573 180.761902,435.362823 
	C181.825882,433.574036 182.843689,431.757782 183.875519,429.949860 
	C184.827271,431.862305 185.873993,433.735260 186.691895,435.703339 
	C187.118805,436.730621 187.143005,437.925293 188.293900,439.313721 
	C189.294464,436.114655 190.295029,432.915619 191.295578,429.716583 
	C191.834610,429.775787 192.373627,429.835022 192.912659,429.894257 
	C192.912659,431.426605 193.199753,433.027588 192.853149,434.477081 
	C192.121887,437.535400 191.163498,440.553070 190.071976,443.503723 
	C189.718430,444.459473 188.659134,445.154114 187.922653,445.968170 
	C187.089035,445.085388 185.954498,444.335663 185.491669,443.288971 
	C184.780640,441.680878 184.531219,439.868683 183.843185,437.202423 
	C182.160980,440.719391 180.878113,443.401459 179.595245,446.083557 
	C178.981796,446.116089 178.368347,446.148621 177.754883,446.181152 
	C176.837173,442.041077 175.919464,437.900970 174.975281,433.362488 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M155.265259,445.465851 
	C154.712219,445.741150 154.250015,445.970734 154.117233,445.846954 
	C151.368439,443.283997 149.855179,433.492889 152.268951,428.706421 
	C153.218689,432.344757 153.907089,434.981903 154.935867,438.923035 
	C156.555908,435.244659 157.773361,432.480347 158.990829,429.716064 
	C159.432526,429.707153 159.874222,429.698242 160.315903,429.689331 
	C161.190216,432.279907 162.064514,434.870453 163.217728,438.287384 
	C164.824020,434.929932 166.087234,432.289581 167.350449,429.649261 
	C168.022064,429.912262 168.693680,430.175293 169.365295,430.438293 
	C167.775604,435.746216 166.185928,441.054138 164.596252,446.362030 
	C163.834793,446.377350 163.073334,446.392670 162.311874,446.407990 
	C161.328354,443.677704 160.344833,440.947418 159.116638,437.537964 
	C157.784271,440.388611 156.646881,442.822113 155.265259,445.465851 
z"/>
<path fill="#24192E" opacity="1.000000" stroke="none" 
	d="
M193.060928,75.056938 
	C191.213776,78.343430 189.664627,81.849014 187.447968,84.863823 
	C184.312195,89.128670 180.798691,93.135948 177.230011,97.056091 
	C174.267929,100.309853 174.582214,102.905151 177.478271,106.132271 
	C182.453918,111.676704 187.092072,117.533630 191.675323,123.412621 
	C192.605484,124.605728 192.665283,126.477364 193.197968,128.407440 
	C193.269608,128.778671 193.038422,128.965744 192.650635,128.910004 
	C191.177155,127.894981 190.091476,126.935699 189.001999,125.990463 
	C188.998215,126.004509 189.025787,126.002548 188.953049,125.749771 
	C188.592133,125.314514 188.303955,125.132042 188.010590,124.976364 
	C188.005386,125.003151 188.050095,124.971802 188.015411,124.618874 
	C182.330032,116.871025 176.679321,109.476097 171.091461,101.754532 
	C171.183746,100.985535 171.213181,100.543167 171.497986,99.903252 
	C172.944565,98.342369 174.149521,96.990761 175.324661,95.613724 
	C181.199402,88.729668 187.066284,81.838936 192.967361,74.976944 
	C192.998779,75.003349 193.060928,75.056938 193.060928,75.056938 
z"/>
<path fill="#DCE44E" opacity="1.000000" stroke="none" 
	d="
M373.085510,144.787247 
	C369.712372,143.973633 368.820709,141.917053 368.842285,138.441254 
	C369.009369,111.562317 368.935822,84.681892 368.936829,57.801926 
	C368.936890,56.184563 368.936829,54.567204 368.936829,52.397465 
	C374.856232,52.397465 380.295502,52.397465 386.165527,52.345161 
	C386.661346,52.469501 386.726440,52.646137 386.898682,53.426628 
	C387.047150,55.336342 387.088531,56.642204 387.033020,57.901260 
	C386.936188,57.854462 386.733734,57.930004 386.575989,57.934124 
	C386.418243,57.938244 386.102692,57.943638 386.003265,57.583572 
	C383.663940,54.327015 376.525940,52.289524 373.032745,54.557804 
	C371.262207,55.707516 370.191254,59.164848 370.171234,61.590195 
	C369.968262,86.182610 370.029419,110.777390 370.061615,135.371445 
	C370.069122,141.110550 370.739288,141.597824 378.185638,142.795029 
	C382.222473,143.444046 385.534790,143.122086 386.252960,138.050446 
	C386.466705,138.059357 386.893585,138.099075 386.954407,138.503006 
	C387.063843,140.268387 387.112427,141.629852 387.081818,143.290649 
	C386.957886,143.791519 386.913147,143.993057 386.478119,144.245544 
	C381.753723,144.460083 377.419617,144.623672 373.085510,144.787247 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M212.539124,423.186920 
	C215.201279,421.844696 215.363922,421.835419 215.512680,424.443176 
	C215.690613,427.562714 215.554642,430.700165 215.554642,435.216370 
	C218.893600,432.505707 221.352921,430.509186 223.812225,428.512665 
	C224.262192,428.915802 224.712173,429.318909 225.162140,429.722015 
	C223.805725,431.682800 222.449295,433.643585 221.005859,435.730164 
	C222.445221,438.660583 223.980881,441.787048 225.516556,444.913544 
	C224.935379,445.193115 224.354187,445.472687 223.773010,445.752258 
	C221.931244,443.306580 220.089478,440.860870 217.335327,437.203613 
	C216.008026,440.489868 215.041626,442.882629 214.075211,445.275360 
	C213.574173,445.289276 213.073120,445.303162 212.572067,445.317078 
	C211.350342,438.151184 213.153717,430.772644 212.539124,423.186920 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M243.886078,202.619476 
	C242.033478,203.602692 240.203613,204.201126 237.959991,204.934875 
	C237.769760,204.806808 236.838333,204.179749 235.652954,203.404587 
	C235.399002,203.256470 235.886047,203.204788 235.758194,202.964966 
	C235.353928,202.495743 235.077499,202.266312 234.566925,201.777390 
	C232.843307,197.734177 231.353867,193.950485 229.864426,190.166779 
	C231.298843,190.321625 232.733276,190.476471 234.891479,190.709442 
	C234.044632,186.668671 233.296127,183.097153 232.768463,179.264999 
	C236.629135,186.747787 240.268982,194.491241 243.886078,202.619476 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M313.871155,432.203186 
	C317.854553,442.194458 313.975250,446.092926 301.337463,444.793274 
	C301.404419,437.219025 300.429199,429.564056 301.735229,422.036621 
	C302.417664,422.031769 303.100098,422.026917 303.782532,422.022064 
	C303.929321,424.654236 304.076141,427.286407 304.161713,428.820374 
	C307.731415,429.980896 310.689301,430.942535 313.871155,432.203186 
M304.041107,435.176819 
	C304.573944,437.751251 302.081329,441.669586 306.492371,442.440338 
	C308.196350,442.738068 311.193024,441.309662 311.945526,439.827332 
	C312.739319,438.263611 312.250153,435.143372 311.087585,433.690369 
	C309.014954,431.099976 306.406403,432.437378 304.041107,435.176819 
z"/>
<path fill="#EAF2F5" opacity="1.000000" stroke="none" 
	d="
M129.906128,325.284668 
	C131.355591,319.464844 132.869461,313.909821 134.505997,307.904694 
	C136.419937,308.427490 138.217026,308.918365 140.228119,309.467682 
	C139.104553,313.958374 138.068436,318.099609 136.885300,322.828461 
	C139.803574,323.706726 142.703354,324.579407 145.994537,325.569916 
	C145.721649,326.939209 145.450195,328.301270 145.101746,330.049683 
	C140.197983,328.881653 135.596268,327.785583 130.587784,326.651886 
	C130.110855,326.259308 130.040695,325.904388 129.906128,325.284668 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M262.809204,441.779419 
	C265.517609,442.035767 267.859894,442.282501 270.202179,442.529236 
	C270.380524,441.832947 270.558868,441.136688 270.737183,440.440399 
	C269.170441,439.660278 267.516602,439.012909 266.063324,438.059723 
	C264.807373,437.235962 262.760559,435.938934 262.867279,435.073547 
	C263.075653,433.383698 264.353790,431.679810 265.566162,430.317383 
	C266.237213,429.563293 267.645355,429.320190 268.767700,429.145538 
	C270.576080,428.864136 273.206573,426.438812 273.796234,430.171295 
	C274.010437,431.527130 272.313660,433.184906 271.058197,435.498505 
	C270.952881,435.316681 271.474670,436.470062 272.219482,437.454712 
	C274.187561,440.056396 274.958160,443.323090 271.695587,444.525421 
	C268.889099,445.559692 265.280151,444.416565 262.026031,444.236206 
	C262.165070,443.414062 262.304077,442.591949 262.809204,441.779419 
z"/>
<path fill="#2C80B7" opacity="1.000000" stroke="none" 
	d="
M91.078720,225.506744 
	C91.755432,226.842133 92.501976,228.591293 92.965126,230.412506 
	C94.068497,234.751266 95.037971,239.124069 96.061531,243.483124 
	C96.798195,243.491058 97.534866,243.498978 98.271538,243.506912 
	C98.738808,240.869751 99.536079,238.237747 99.577263,235.593948 
	C99.615723,233.124390 98.851532,230.642334 98.977173,228.094421 
	C99.688377,228.026627 99.863029,228.030167 100.141815,228.417297 
	C102.145081,236.207611 104.044220,243.614349 105.943359,251.021103 
	C104.618065,250.150879 103.292778,249.280655 102.265175,248.605911 
	C100.681206,249.765671 99.145157,250.890350 97.221146,252.022690 
	C92.505295,243.870804 90.974274,235.087769 91.078720,225.506744 
z"/>
<path fill="#6BD0DD" opacity="1.000000" stroke="none" 
	d="
M254.466583,245.731659 
	C255.435898,245.089981 256.862610,244.072464 258.294159,244.065582 
	C279.517303,243.963364 300.741180,243.978485 321.964630,244.037857 
	C323.191162,244.041290 324.415955,244.662201 325.816650,245.330780 
	C302.303253,245.719162 278.614746,245.772522 254.466583,245.731659 
z"/>
<path fill="#11693B" opacity="1.000000" stroke="none" 
	d="
M307.354095,64.171387 
	C307.698029,64.100868 308.041931,64.030357 308.647217,63.923767 
	C315.216980,67.010574 315.613831,68.013161 311.587219,73.433739 
	C309.596619,76.113503 306.978607,78.327194 304.120911,80.590729 
	C303.106598,80.255646 302.615814,80.081612 302.125061,79.907570 
	C302.125061,79.907570 302.145782,79.853813 302.051666,79.527550 
	C300.932312,77.865707 299.907074,76.530121 299.170380,74.995308 
	C301.023834,72.867859 302.588684,70.939636 304.415955,68.822960 
	C305.503815,67.127083 306.329254,65.619659 307.196533,64.154083 
	C307.238342,64.195915 307.354095,64.171387 307.354095,64.171387 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M236.923828,432.803833 
	C236.268326,433.553680 235.933899,434.141510 235.599487,434.729309 
	C236.684341,435.131470 238.070023,435.244110 238.792480,435.994965 
	C240.380356,437.645111 242.838654,439.732422 242.677704,441.426178 
	C242.521866,443.066254 239.727768,445.239288 237.736649,445.727631 
	C235.110809,446.371704 231.715149,446.403809 230.616501,441.959900 
	C233.833328,441.959900 236.753296,441.959900 239.673279,441.959900 
	C239.779236,441.415436 239.885208,440.870972 239.991180,440.326538 
	C238.332047,439.568878 236.549484,438.995972 235.052429,437.995758 
	C233.828766,437.178223 232.218338,435.918701 232.107651,434.731262 
	C231.986755,433.434509 233.055008,431.379974 234.205582,430.746185 
	C236.600662,429.426941 239.391891,428.826935 242.680771,427.706299 
	C242.923370,429.959381 243.097229,431.573975 243.249146,432.984772 
	C240.811691,432.845551 239.028290,432.743652 236.923828,432.803833 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M289.131409,445.303802 
	C283.011902,446.078644 281.290253,444.881073 281.075287,439.332764 
	C280.946106,435.999573 279.822510,432.295898 282.269165,429.402496 
	C282.848541,429.440582 283.427917,429.478668 284.007294,429.516724 
	C284.007294,432.510071 283.681824,435.554718 284.142456,438.475372 
	C284.378326,439.971069 285.828156,442.177673 287.065674,442.433014 
	C290.023254,443.043274 290.322449,440.667084 290.328156,438.258148 
	C290.334991,435.376404 290.644470,432.495361 291.949707,429.608124 
	C293.842468,434.918549 293.144470,440.329865 293.281158,446.189758 
	C291.620178,445.758148 290.565125,445.484009 289.131409,445.303802 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M365.842590,438.667175 
	C364.810822,442.852539 363.112061,446.203674 358.576752,445.827179 
	C355.037903,445.533417 353.127838,443.047607 352.817505,439.425690 
	C352.369171,434.193085 355.019745,430.198608 359.246429,429.843658 
	C363.518524,429.484894 365.353638,431.811218 365.842590,438.667175 
M362.490234,440.622894 
	C362.569946,438.839966 363.128113,436.882568 362.563507,435.334656 
	C362.178406,434.278870 359.743347,432.890015 359.073242,433.246155 
	C357.721832,433.964539 356.367737,435.666565 356.099823,437.157806 
	C355.829254,438.663544 356.415649,440.934845 357.494049,441.864594 
	C358.262054,442.526733 360.466583,441.522552 362.490234,440.622894 
z"/>
<path fill="#0A1F27" opacity="1.000000" stroke="none" 
	d="
M394.850342,150.250366 
	C394.440826,150.414215 394.078003,150.141953 393.400208,149.453064 
	C392.962952,148.740936 392.855774,148.440079 392.829834,147.681610 
	C392.848572,144.479187 392.801178,141.728973 392.782806,138.512512 
	C392.772278,112.112480 392.732727,86.178680 392.796112,59.945641 
	C392.953217,59.442524 393.007416,59.238636 393.061584,59.034752 
	C396.934753,59.903248 398.141754,61.676632 395.608063,65.319748 
	C394.655609,66.689301 394.089020,68.625725 394.080322,70.307510 
	C393.978821,89.902939 393.943085,109.499519 394.059448,129.094788 
	C394.100464,136.002686 394.604462,142.907837 394.850342,150.250366 
z"/>
<path fill="#49AECE" opacity="1.000000" stroke="none" 
	d="
M129.364655,289.201416 
	C133.108276,290.436981 137.009277,292.056793 141.006165,293.388916 
	C143.319061,294.159760 145.786392,294.467285 148.975616,295.057800 
	C153.840164,295.754425 157.913300,296.376617 162.229340,297.216614 
	C163.653412,297.289825 164.834579,297.145142 166.343567,297.000488 
	C167.108170,297.000763 167.544968,297.000977 168.258423,297.233368 
	C173.005966,297.643799 177.476852,297.822113 181.947739,298.000397 
	C181.947739,298.000397 181.977036,298.428558 181.992218,298.642609 
	C163.335205,302.374084 145.901779,298.973785 129.364655,289.201416 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M345.189240,430.897217 
	C344.826660,431.767059 343.874634,433.026184 343.582062,432.888092 
	C341.016724,431.677277 338.820435,431.929077 337.855194,434.684570 
	C337.146271,436.708344 336.286560,439.934998 337.258026,440.970184 
	C338.442383,442.232178 341.472321,441.781586 343.709045,442.025360 
	C344.435150,442.104492 345.177094,442.037781 346.149506,442.037781 
	C343.795715,446.011841 340.475769,446.977539 337.013062,445.029114 
	C333.562897,443.087738 332.624390,438.980988 334.611481,434.520416 
	C336.843201,429.510681 339.489990,428.512604 345.189240,430.897217 
z"/>
<path fill="#023A23" opacity="1.000000" stroke="none" 
	d="
M268.570740,84.382355 
	C268.832520,83.443939 269.094299,82.505516 269.541748,81.258858 
	C272.605652,82.303757 275.408356,83.858078 278.376465,84.971573 
	C286.626862,88.066734 294.959045,90.943832 303.216492,94.020813 
	C303.981812,94.305984 304.445251,95.401215 305.023132,96.563889 
	C304.997437,97.007454 304.833160,97.195992 304.503723,97.085808 
	C296.943665,94.287018 289.737152,91.530762 282.473541,88.934380 
	C277.883392,87.293625 273.207947,85.891510 268.570740,84.382355 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M117.062340,322.235535 
	C118.451286,322.321350 119.836151,322.796021 121.649185,323.417419 
	C122.252434,322.319458 122.893204,321.153137 123.483772,320.078247 
	C121.814270,318.818848 120.459267,317.796722 119.210442,316.854675 
	C121.426758,315.760101 123.407501,314.781860 125.901306,313.550232 
	C123.928009,312.072357 122.953972,311.342865 121.119461,309.968933 
	C124.065178,309.968933 125.988647,309.968933 128.216736,309.778137 
	C127.583214,314.626831 126.867935,319.725739 125.550964,324.664215 
	C125.219894,325.905670 122.301254,327.741913 121.708748,327.390045 
	C119.865280,326.295441 118.567627,324.281647 117.062340,322.235535 
z"/>
<path fill="#4A1316" opacity="1.000000" stroke="none" 
	d="
M128.836578,54.365692 
	C128.818802,54.005341 129.297806,54.011803 129.612579,54.483536 
	C129.889740,83.627617 129.852127,112.299957 129.814529,140.972305 
	C129.540665,138.867416 129.034973,136.763260 129.028214,134.657516 
	C128.946075,109.050171 128.937378,83.442604 128.840378,57.358856 
	C128.798904,56.163765 128.826630,55.444908 128.836578,54.365692 
z"/>
<path fill="#023A23" opacity="1.000000" stroke="none" 
	d="
M298.881805,75.194534 
	C299.907074,76.530121 300.932312,77.865707 302.029480,79.509186 
	C300.395233,79.288536 298.365875,79.180557 297.037964,78.159904 
	C290.694885,73.284561 283.629669,74.756279 276.715546,75.711105 
	C274.608612,76.002075 272.700134,77.729980 270.347656,78.824448 
	C271.416718,74.216583 275.288300,72.953766 279.312897,73.090233 
	C285.337524,73.294510 291.336029,74.270065 297.937622,74.968933 
	C298.529633,75.020294 298.881805,75.194542 298.881805,75.194534 
z"/>
<path fill="#05324F" opacity="1.000000" stroke="none" 
	d="
M376.428772,444.922760 
	C376.050659,440.570740 375.048981,435.795898 380.875427,432.662964 
	C381.269867,437.180115 381.617279,441.158905 381.950348,445.577087 
	C380.975769,445.001770 380.015564,443.987000 378.884094,442.791229 
	C378.300201,443.343719 377.514374,444.087280 376.428772,444.922760 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M167.907791,312.545624 
	C163.315491,312.924561 158.634354,312.944000 153.568726,313.235291 
	C153.090485,309.730438 155.343491,308.448669 158.560928,309.139832 
	C161.719315,309.818298 164.738617,311.144257 167.907791,312.545624 
z"/>
<path fill="#72C1D4" opacity="1.000000" stroke="none" 
	d="
M251.055481,440.707642 
	C251.278885,436.835693 251.508774,433.417542 251.738663,429.999420 
	C252.340271,430.031281 252.941879,430.063110 253.543488,430.094971 
	C253.543488,434.985199 253.543488,439.875427 253.543488,444.765656 
	C253.130554,444.870331 252.717621,444.975006 252.304688,445.079681 
	C251.890442,443.773621 251.476196,442.467560 251.055481,440.707642 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M238.244324,299.556610 
	C239.917053,301.466248 241.589798,303.375885 243.632263,305.260803 
	C242.736389,310.734772 237.934189,311.435608 233.305130,312.910370 
	C232.480209,311.528503 232.013412,310.093506 231.616608,308.873596 
	C234.383484,308.873596 237.033783,308.873596 240.314606,308.873596 
	C239.072754,305.029449 238.228394,302.415680 237.821167,299.639038 
	C238.258331,299.476196 238.244324,299.556610 238.244324,299.556610 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M152.927292,331.722504 
	C157.961121,331.602692 163.018433,331.844635 168.537689,332.038849 
	C167.722107,332.968536 165.439590,334.913605 165.313354,334.782501 
	C161.742172,331.073456 155.802292,338.282410 152.927292,331.722504 
z"/>
<path fill="#023A23" opacity="1.000000" stroke="none" 
	d="
M315.793182,108.816513 
	C314.477692,108.137558 313.165802,107.091957 311.325439,105.700317 
	C308.809021,102.634857 306.821106,99.915421 304.833160,97.195984 
	C304.833160,97.195992 304.997437,97.007454 305.080383,96.911011 
	C311.782013,97.883507 313.015564,103.873894 315.793182,108.816513 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M177.875046,312.886353 
	C182.489105,306.757782 187.336838,306.561462 192.009811,312.556152 
	C192.142899,312.856201 191.986710,313.010010 191.561432,312.954559 
	C186.735519,309.649536 182.398880,311.306030 178.063629,313.069672 
	C178.063629,313.069672 177.938339,312.947021 177.875046,312.886353 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M127.777939,304.908203 
	C124.599930,303.861542 121.417496,302.452545 117.956833,300.865387 
	C122.850075,297.155457 124.139999,297.648529 127.777939,304.908203 
z"/>
<path fill="#24192E" opacity="1.000000" stroke="none" 
	d="
M201.042984,65.041061 
	C201.170593,70.229660 197.491425,72.592041 193.394958,74.974770 
	C193.060928,75.056938 192.998779,75.003349 193.048035,74.639290 
	C194.084396,72.205643 195.071518,70.136063 196.070602,68.064758 
	C196.082565,68.063034 196.110123,68.082664 196.434570,68.039116 
	C198.137375,66.969650 199.515747,65.943726 200.931549,64.948349 
	C200.968994,64.978897 201.042984,65.041061 201.042984,65.041061 
z"/>
<path fill="#11693B" opacity="1.000000" stroke="none" 
	d="
M244.064423,131.131775 
	C244.942596,131.318848 245.602600,131.712128 246.590973,132.116821 
	C246.919327,132.128235 246.936035,132.045013 246.907257,132.350479 
	C247.249863,133.114807 247.621231,133.573654 247.997925,134.013748 
	C248.003220,133.994980 247.966965,134.009384 248.102661,134.290375 
	C249.237488,135.618073 250.236603,136.664780 251.235718,137.711502 
	C251.235718,137.711502 251.046463,137.935150 250.950378,138.047104 
	C248.518265,135.885361 246.182251,133.611664 244.064423,131.131775 
z"/>
<path fill="#24192E" opacity="1.000000" stroke="none" 
	d="
M204.055099,58.947868 
	C207.497543,62.543037 204.914291,63.869186 201.404480,65.010139 
	C201.042984,65.041061 200.968994,64.978897 201.019318,64.622887 
	C202.037521,62.519337 203.005402,60.771801 203.973282,59.024261 
	C203.973282,59.024265 204.027618,58.973118 204.055099,58.947868 
z"/>
<path fill="#1F2E08" opacity="1.000000" stroke="none" 
	d="
M387.129883,57.948063 
	C387.088531,56.642204 387.047150,55.336342 387.065308,53.654598 
	C388.342407,54.639614 389.559937,56.000515 390.470581,57.711571 
	C389.477844,58.213760 388.792023,58.365795 387.865967,58.365158 
	C387.460480,58.124352 387.295197,58.036209 387.129883,57.948063 
z"/>
<path fill="#2C80B7" opacity="1.000000" stroke="none" 
	d="
M98.215683,255.232544 
	C100.072243,256.040192 101.910332,257.150391 103.748413,258.260590 
	C103.557915,258.667786 103.367424,259.074951 103.176926,259.482117 
	C102.222008,259.078278 101.267082,258.674438 100.013870,258.143616 
	C99.209457,257.189453 98.703331,256.362274 98.215683,255.232544 
z"/>
<path fill="#2C80B7" opacity="1.000000" stroke="none" 
	d="
M92.970200,213.695877 
	C92.582809,214.987396 92.194283,215.897644 91.516541,216.930176 
	C91.179077,216.598450 91.130829,216.144470 91.293648,215.149292 
	C91.590080,214.267105 91.675446,213.926102 91.760811,213.585098 
	C91.782227,212.956436 91.803642,212.327774 91.949142,211.061432 
	C92.076370,209.980713 92.079506,209.537674 92.082634,209.094650 
	C92.082634,209.094650 92.047310,209.091248 92.068130,209.097717 
	C92.382324,210.507660 92.675697,211.911148 92.970200,213.695877 
z"/>
<path fill="#6BD0DD" opacity="1.000000" stroke="none" 
	d="
M250.627258,240.941711 
	C250.658356,242.281631 250.318237,243.544861 249.944092,245.211578 
	C248.986359,244.865860 248.062668,244.116669 247.138977,243.367462 
	C248.178009,242.533325 249.217026,241.699188 250.627258,240.941711 
z"/>
<path fill="#465A41" opacity="1.000000" stroke="none" 
	d="
M388.106171,58.517830 
	C388.792023,58.365795 389.477844,58.213760 390.511810,58.044113 
	C391.443176,58.221478 392.026459,58.416458 392.835632,58.823097 
	C393.007416,59.238636 392.953217,59.442524 392.483887,59.829796 
	C390.774719,59.672169 389.480743,59.331154 388.152161,58.874535 
	C388.117554,58.758926 388.106171,58.517830 388.106171,58.517830 
z"/>
<path fill="#49AECE" opacity="1.000000" stroke="none" 
	d="
M185.412659,298.178070 
	C186.398972,297.743713 187.749649,297.337128 189.472763,296.938232 
	C188.489136,297.365906 187.133057,297.785889 185.412659,298.178070 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M229.052917,173.644836 
	C228.466415,173.431793 227.861130,172.908279 227.273163,172.050507 
	C227.871689,172.255615 228.452911,172.794968 229.052917,173.644836 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M227.204468,171.423737 
	C226.616287,171.273239 225.941895,170.837097 225.287811,170.054962 
	C225.911499,170.185349 226.514877,170.661697 227.204468,171.423737 
z"/>
<path fill="#2C80B7" opacity="1.000000" stroke="none" 
	d="
M91.113617,219.354980 
	C91.333191,219.640427 91.480324,220.243652 91.385803,220.906219 
	C91.109818,220.534637 91.075485,220.103699 91.113617,219.354980 
z"/>
<path fill="#175FA9" opacity="1.000000" stroke="none" 
	d="
M91.903595,209.246613 
	C92.079506,209.537674 92.076370,209.980713 92.021454,210.753876 
	C91.887970,210.522202 91.806267,209.960403 91.903595,209.246613 
z"/>
<path fill="#023A23" opacity="1.000000" stroke="none" 
	d="
M302.285980,80.129349 
	C302.615814,80.081612 303.106598,80.255646 303.779907,80.615036 
	C303.457306,80.650627 302.952087,80.500870 302.285980,80.129349 
z"/>
<path fill="#49AECE" opacity="1.000000" stroke="none" 
	d="
M127.739487,288.254456 
	C127.996605,288.240387 128.357468,288.410126 128.865601,288.724548 
	C128.622986,288.725616 128.233109,288.581940 127.739487,288.254456 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M229.759918,174.820633 
	C229.579895,174.877335 229.353912,174.661713 229.115845,174.150726 
	C229.277496,174.123825 229.480896,174.354797 229.759918,174.820633 
z"/>
<path fill="#175FA9" opacity="1.000000" stroke="none" 
	d="
M91.566589,213.775513 
	C91.675446,213.926102 91.590080,214.267105 91.330574,214.807877 
	C91.228416,214.660416 91.300400,214.313171 91.566589,213.775513 
z"/>
<path fill="#175FA9" opacity="1.000000" stroke="none" 
	d="
M90.958893,217.191772 
	C91.077065,217.125031 91.079918,217.517303 91.063087,217.712097 
	C90.755249,217.758972 90.686737,217.542847 90.958893,217.191772 
z"/>
<path fill="#11693B" opacity="1.000000" stroke="none" 
	d="
M306.850769,59.806843 
	C306.682831,59.858376 306.391907,59.687363 305.987000,59.284737 
	C306.157959,59.230186 306.442841,59.407242 306.850769,59.806843 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M90.998650,289.000977 
	C90.997299,289.001984 90.714607,288.823853 90.714607,288.823853 
	C90.714607,288.823853 90.916000,288.841003 90.958954,288.919128 
	C91.001900,288.997284 91.000000,289.000000 90.998650,289.000977 
z"/>
<path fill="#11693B" opacity="1.000000" stroke="none" 
	d="
M308.825989,61.869663 
	C308.597534,61.975647 308.345062,61.770370 308.060150,61.259865 
	C308.295197,61.140514 308.553284,61.341770 308.825989,61.869663 
z"/>
<path fill="#11693B" opacity="1.000000" stroke="none" 
	d="
M307.858093,60.817375 
	C307.694275,60.856346 307.420776,60.676502 307.059021,60.261749 
	C307.293579,60.130924 307.552795,60.321499 307.858093,60.817375 
z"/>
<path fill="#49AECE" opacity="1.000000" stroke="none" 
	d="
M246.241455,225.165100 
	C246.057373,225.103455 246.029510,224.870407 246.093689,224.460052 
	C246.334427,224.486679 246.405075,224.723663 246.241455,225.165100 
z"/>
<path fill="#052345" opacity="1.000000" stroke="none" 
	d="
M437.012238,216.818359 
	C435.395416,225.123322 430.794312,229.099457 423.439697,228.943054 
	C417.318939,228.812912 412.079651,224.190918 411.101471,218.058670 
	C410.101685,211.790771 413.467255,205.883789 419.281555,203.701736 
	C428.098633,200.392776 436.279358,206.247360 437.012238,216.818359 
z"/>
<path fill="#052345" opacity="1.000000" stroke="none" 
	d="
M401.684143,295.236450 
	C398.177704,303.093384 393.426727,306.031738 386.513336,304.929535 
	C381.125336,304.070496 376.484802,298.875946 376.026306,293.190399 
	C375.432495,285.827362 380.526276,279.553131 387.552948,278.992523 
	C395.422821,278.364655 401.566345,283.628387 401.952118,291.374603 
	C402.009583,292.528503 401.858246,293.692780 401.684143,295.236450 
z"/>
<path fill="#052345" opacity="1.000000" stroke="none" 
	d="
M388.850769,151.710480 
	C395.284332,160.551727 393.693176,170.165741 385.186493,173.850845 
	C379.031555,176.517151 372.458679,174.317245 368.904968,168.401550 
	C365.124756,162.108734 366.551056,155.804306 373.595764,150.222061 
	C379.114471,150.504242 383.982635,151.107361 388.850769,151.710480 
z"/>
<path fill="#9BDA8B" opacity="1.000000" stroke="none" 
	d="
M389.137817,151.514862 
	C383.982635,151.107361 379.114471,150.504242 373.826599,149.949371 
	C372.930725,148.840683 372.454620,147.683762 371.871582,146.237854 
	C372.078461,145.647522 372.392303,145.346161 372.895813,144.916031 
	C377.419617,144.623672 381.753723,144.460083 386.673676,144.512894 
	C388.490295,145.135849 389.721069,145.542419 391.020874,146.268524 
	C391.647858,147.103302 392.205811,147.618561 392.763733,148.133820 
	C392.855774,148.440079 392.962952,148.740936 393.112244,149.267883 
	C391.901123,150.105988 390.662994,150.712616 389.137817,151.514862 
z"/>
<path fill="#042145" opacity="1.000000" stroke="none" 
	d="
M211.022308,226.855087 
	C218.266876,226.916534 225.514938,226.873123 232.753586,227.112900 
	C234.571823,227.173126 236.359985,228.141830 238.501648,228.867920 
	C237.222244,234.098297 235.679413,239.180023 233.954666,244.199234 
	C232.966400,247.075256 231.682617,249.849762 230.108429,253.111832 
	C229.333313,253.923660 228.981659,254.293655 228.630020,254.663651 
	C226.361984,254.662354 224.093933,254.661041 221.040390,254.655945 
	C219.501495,254.714417 218.748108,254.776703 217.660904,254.622513 
	C214.027618,254.207214 210.728149,254.008392 207.406021,253.808212 
	C207.623627,251.158630 207.794037,249.083557 208.009506,246.770660 
	C208.054550,246.532806 208.043350,246.048782 208.156601,245.914673 
	C208.380005,245.539474 208.346573,245.320282 208.309067,244.723297 
	C208.589935,243.208008 208.731277,242.092407 208.979294,240.574814 
	C209.426590,235.872406 209.767212,231.572006 210.341309,227.178131 
	C210.723953,227.008118 210.873123,226.931595 211.022308,226.855087 
z"/>
<path fill="#4AB9D4" opacity="1.000000" stroke="none" 
	d="
M217.994720,254.838989 
	C218.748108,254.776703 219.501495,254.714417 220.621567,254.755356 
	C221.917770,256.746552 222.847290,258.634521 224.355713,261.698303 
	C219.027954,261.698303 214.385239,261.653534 209.744308,261.724060 
	C208.243362,261.746887 206.747025,262.072266 204.780319,262.712280 
	C202.095459,268.062500 199.878860,272.959747 197.415039,278.072144 
	C195.994568,280.218323 194.710999,282.096069 193.732208,284.121094 
	C193.510986,284.578766 194.350098,285.549011 194.701126,286.283295 
	C195.217041,287.615356 195.732941,288.947418 195.797195,289.113312 
	C190.956177,292.031311 186.553894,294.684845 182.049683,297.669403 
	C177.476852,297.822113 173.005966,297.643799 168.449280,296.944397 
	C170.274475,295.305115 172.185440,294.186981 173.888260,293.190643 
	C172.600342,291.329285 170.660553,289.806335 170.756241,288.424347 
	C171.044907,284.255707 172.124878,280.141846 172.972565,276.464478 
	C173.048843,281.115540 173.048843,285.308807 173.048843,289.502045 
	C173.590515,289.759094 174.132202,290.016113 174.673874,290.273132 
	C177.764511,287.660522 181.513336,285.518585 183.795135,282.327606 
	C187.429871,277.244598 190.087540,271.462952 193.337128,265.771240 
	C193.634689,265.224152 193.753555,264.882599 193.778687,264.326416 
	C193.630997,263.803284 193.610519,263.492615 194.010193,263.129089 
	C195.315323,262.102020 196.273209,261.159027 197.139938,260.138641 
	C197.848755,259.304169 198.549042,257.555847 199.081329,257.620789 
	C203.534225,258.164001 203.750748,254.474945 205.142120,252.304230 
	C206.784302,256.769562 212.066391,257.661316 217.994720,254.838989 
z"/>
<path fill="#04274B" opacity="1.000000" stroke="none" 
	d="
M197.662262,277.856964 
	C199.878860,272.959747 202.095459,268.062500 204.657745,263.054260 
	C207.996521,262.999115 210.989410,263.075348 213.982742,263.104950 
	C216.781555,263.132629 219.580856,263.110870 223.812637,263.110870 
	C217.459351,271.772797 210.501373,277.764984 201.413727,281.942413 
	C199.744797,280.466644 198.703537,279.161804 197.662262,277.856964 
z"/>
<path fill="#05324F" opacity="1.000000" stroke="none" 
	d="
M197.415039,278.072144 
	C198.703537,279.161804 199.744797,280.466644 201.072647,281.992950 
	C199.309540,283.443481 197.259872,284.672607 194.955658,286.092499 
	C194.350098,285.549011 193.510986,284.578766 193.732208,284.121094 
	C194.710999,282.096069 195.994568,280.218323 197.415039,278.072144 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M228.926086,254.666107 
	C228.981659,254.293655 229.333313,253.923660 229.898468,253.410049 
	C229.815353,253.733795 229.518753,254.201187 228.926086,254.666107 
z"/>
<path fill="#052345" opacity="1.000000" stroke="none" 
	d="
M335.205963,235.449112 
	C342.121033,228.662674 350.167236,228.386826 355.492157,234.439484 
	C360.532349,240.168503 359.834259,248.477982 353.893219,253.471283 
	C349.409912,257.239471 342.337341,257.310791 337.819794,253.633408 
	C332.334351,249.168152 331.305206,242.660095 335.205963,235.449112 
z"/>
<path fill="#042047" opacity="1.000000" stroke="none" 
	d="
M106.012802,218.063583 
	C104.681274,218.000046 103.349754,217.936508 101.623337,217.866943 
	C100.419167,207.903320 103.459267,198.995361 109.271957,190.413361 
	C111.947418,190.124359 113.993225,190.093735 116.456940,190.125061 
	C118.561867,190.157043 120.248863,190.127106 122.242607,190.268250 
	C125.171501,190.638000 127.793663,190.836670 130.358978,191.336273 
	C130.374634,192.034149 130.447144,192.431076 130.394897,193.246613 
	C129.501846,201.441086 128.733536,209.216919 127.748032,217.168182 
	C127.022888,217.570282 126.514938,217.796982 125.546921,217.947296 
	C120.723625,217.931946 116.360413,217.992966 111.997192,218.053955 
	C110.604370,218.015182 109.211540,217.976410 107.159447,217.958710 
	C106.500175,217.979797 106.012802,218.063583 106.012802,218.063583 
z"/>
<path fill="#1B68B0" opacity="1.000000" stroke="none" 
	d="
M144.960938,150.222107 
	C147.594986,149.036469 150.331192,148.237976 153.295074,147.206024 
	C153.522766,146.972565 153.790039,146.932343 154.270935,146.888016 
	C156.462845,146.544159 158.173843,146.244614 159.884842,145.945068 
	C163.579361,145.945068 167.273865,145.945068 172.393570,145.945068 
	C168.767639,149.199707 162.912399,148.327454 163.698303,154.512833 
	C155.985229,157.798340 152.764160,164.686310 148.017624,170.075806 
	C146.927399,170.754333 145.801529,171.511703 145.253662,172.568665 
	C142.674393,177.544601 141.618256,177.883759 136.142380,174.730789 
	C136.421417,173.582764 136.710098,172.770752 137.266785,171.776520 
	C138.075333,170.414276 138.615860,169.234222 139.380585,167.833313 
	C140.106934,166.465668 140.609085,165.318848 141.364410,163.968185 
	C142.732986,162.177643 143.848389,160.590927 144.963776,159.004196 
	C148.367233,156.245758 145.018112,153.407410 144.960938,150.222107 
z"/>
<path fill="#03204A" opacity="1.000000" stroke="none" 
	d="
M141.111221,164.172012 
	C140.609085,165.318848 140.106934,166.465668 139.028931,167.911621 
	C137.968292,169.460068 137.483536,170.709396 136.998764,171.958725 
	C136.710098,172.770752 136.421417,173.582764 135.850143,174.945419 
	C135.049072,177.353210 134.530609,179.210373 133.664398,181.092102 
	C132.863831,181.217285 132.411011,181.317902 131.600754,181.326111 
	C130.509186,181.279541 129.775070,181.325363 128.682983,181.274231 
	C127.594574,181.225510 126.864113,181.273743 125.845993,181.320465 
	C125.369881,181.354630 125.181450,181.390335 124.614830,181.338837 
	C123.463768,181.295242 122.690880,181.338852 121.917999,181.382477 
	C119.839531,181.136063 117.761063,180.889664 114.727348,180.530029 
	C119.706932,174.016113 124.964371,169.034698 131.922821,165.332703 
	C133.333069,164.653625 134.183105,163.877808 135.033142,163.101974 
	C136.134735,162.438400 137.236313,161.774811 138.813187,161.316071 
	C139.896057,162.404602 140.503632,163.288300 141.111221,164.172012 
z"/>
<path fill="#1B68B0" opacity="1.000000" stroke="none" 
	d="
M131.958206,181.418518 
	C132.411011,181.317902 132.863831,181.217285 133.716614,181.428268 
	C133.550583,183.187180 133.383362,185.398209 132.344772,185.940536 
	C129.050034,187.660995 125.430092,188.758713 121.935867,190.097168 
	C120.248863,190.127106 118.561867,190.157043 116.231140,189.895935 
	C114.390984,188.737030 113.194550,187.869156 111.998123,187.001282 
	C112.392746,180.965347 117.077988,183.643921 120.865021,183.134369 
	C122.733093,182.655151 123.863052,182.040588 124.993011,181.426025 
	C125.181450,181.390335 125.369881,181.354630 126.091209,181.483017 
	C127.408783,181.780075 128.193466,181.913055 129.191772,182.237793 
	C130.256332,182.092545 131.107269,181.755524 131.958206,181.418518 
z"/>
<path fill="#03244D" opacity="1.000000" stroke="none" 
	d="
M141.364410,163.968185 
	C140.503632,163.288300 139.896057,162.404602 139.134323,161.240143 
	C139.200150,160.531494 139.420135,160.103607 140.138260,159.450439 
	C141.761200,158.838760 142.886002,158.452347 144.010803,158.065918 
	C144.258499,158.235504 144.482986,158.431641 144.824036,158.829254 
	C143.848389,160.590927 142.732986,162.177643 141.364410,163.968185 
z"/>
<path fill="#1F70B0" opacity="1.000000" stroke="none" 
	d="
M111.636368,187.024796 
	C113.194550,187.869156 114.390984,188.737030 115.813217,189.834015 
	C113.993225,190.093735 111.947418,190.124359 109.465919,190.133209 
	C109.778351,189.090393 110.526482,188.069351 111.636368,187.024796 
z"/>
<path fill="#1F70B0" opacity="1.000000" stroke="none" 
	d="
M124.614838,181.338837 
	C123.863052,182.040588 122.733093,182.655151 121.301453,183.135452 
	C121.228157,182.593781 121.456551,182.186371 121.801468,181.580719 
	C122.690880,181.338852 123.463768,181.295242 124.614838,181.338837 
z"/>
<path fill="#1B68B0" opacity="1.000000" stroke="none" 
	d="
M143.934906,157.761047 
	C142.886002,158.452347 141.761200,158.838760 140.272980,159.247681 
	C140.682281,157.481644 141.168045,155.068390 143.934906,157.761047 
z"/>
<path fill="#03244D" opacity="1.000000" stroke="none" 
	d="
M134.723328,163.139862 
	C134.183105,163.877808 133.333069,164.653625 132.246613,165.223419 
	C132.811325,164.404175 133.612411,163.790955 134.723328,163.139862 
z"/>
<path fill="#29D43D" opacity="1.000000" stroke="none" 
	d="
M299.170380,74.995316 
	C298.881805,75.194542 298.529633,75.020294 298.345459,74.953964 
	C295.255341,72.755753 292.349396,70.623863 288.980225,68.024948 
	C287.897919,67.424576 287.278870,67.291229 286.659851,67.157875 
	C285.650543,67.089867 284.641235,67.021866 283.020325,66.657814 
	C280.215027,65.238281 277.993958,63.659660 276.053070,66.743645 
	C275.272583,66.800682 274.492065,66.857727 273.259399,66.624542 
	C269.528259,67.762642 265.705414,68.554901 263.105774,70.777725 
	C259.733551,73.661110 260.464539,77.603752 263.906647,80.989052 
	C264.247375,81.450737 264.588104,81.912422 265.032257,83.014679 
	C266.307983,84.979156 267.247589,87.040100 268.693726,87.496750 
	C274.336487,89.278511 280.067535,91.314751 285.897186,91.865562 
	C300.722504,93.266327 314.447235,105.794533 310.573364,122.910789 
	C310.357941,123.862610 310.950592,124.997314 311.167114,126.046890 
	C311.081024,126.493881 310.994995,126.940872 310.470734,127.729233 
	C309.086578,129.855652 308.140594,131.640686 307.194641,133.425720 
	C307.194641,133.425720 307.202850,133.743225 306.796021,133.931931 
	C301.179321,136.740372 295.947845,139.318878 290.783630,142.025681 
	C290.035400,142.417862 289.609528,143.425018 289.034454,144.147598 
	C288.375580,144.186859 287.716705,144.226135 286.232849,144.172028 
	C281.309326,144.345856 277.210815,144.613083 273.112274,144.880310 
	C267.595184,143.416580 262.078094,141.952850 256.202118,139.926971 
	C254.879242,138.923782 253.915253,138.482758 252.951279,138.041718 
	C252.951279,138.041718 253.010086,138.028366 252.952637,137.690140 
	C251.252441,136.237732 249.609711,135.123550 247.966965,134.009369 
	C247.966965,134.009384 248.003220,133.994980 247.976227,133.716782 
	C247.611511,132.974060 247.273773,132.509537 246.936035,132.045013 
	C246.936035,132.045013 246.919327,132.128235 246.879562,132.140930 
	C244.907990,127.444069 248.027496,124.323425 250.511734,121.152634 
	C252.185364,119.016495 254.104996,119.428398 255.961090,121.410721 
	C263.628235,129.599258 273.413605,131.655960 284.135345,131.002502 
	C289.776123,130.658722 295.563324,125.417084 295.957001,120.113647 
	C296.464325,113.279205 292.808319,108.742264 286.443512,106.930145 
	C277.438690,104.366371 268.382935,101.747971 259.746674,98.188004 
	C250.850021,94.520706 247.203873,86.501778 247.190811,77.385239 
	C247.177521,68.117317 251.459549,60.594833 260.258728,56.847176 
	C274.869934,50.624107 289.283966,50.601112 302.865479,59.932297 
	C304.348389,60.951122 305.564270,62.358616 307.129028,63.878609 
	C307.354095,64.171387 307.238342,64.195915 306.875000,64.291718 
	C305.725616,65.928810 304.939575,67.470108 304.153534,69.011414 
	C302.588684,70.939636 301.023834,72.867859 299.170380,74.995316 
z"/>
<path fill="#EAF2F5" opacity="1.000000" stroke="none" 
	d="
M199.554428,309.514954 
	C201.444214,308.109283 203.146622,306.395599 205.204056,305.478088 
	C211.282700,302.767426 216.628448,305.221252 218.693314,311.365295 
	C220.585800,316.996399 217.666214,322.081421 211.461166,323.961548 
	C205.314926,325.823853 200.416077,323.124420 199.055832,316.795715 
	C198.620102,314.768372 199.103256,312.543549 199.292725,310.016937 
	C199.419434,309.624817 199.529297,309.570770 199.554428,309.514954 
M213.258240,315.479004 
	C212.573776,313.832672 212.237503,311.885986 211.131149,310.603668 
	C208.943619,308.068207 205.734802,308.979156 205.223740,311.434418 
	C204.738693,313.764709 206.009613,317.414581 207.812332,319.019745 
	C209.951950,320.924896 212.388931,319.189209 213.258240,315.479004 
z"/>
<path fill="#EAF2F5" opacity="1.000000" stroke="none" 
	d="
M177.890961,313.383728 
	C182.398880,311.306030 186.735519,309.649536 191.483002,313.031097 
	C192.423676,314.506805 193.017532,315.850525 194.268402,318.680847 
	C191.188522,317.948456 189.179016,317.307770 187.120636,317.032104 
	C185.437454,316.806641 183.695953,317.016571 181.980316,317.033478 
	C182.332153,319.819916 182.684006,322.606384 183.035858,325.392822 
	C184.789398,325.090546 186.703705,325.128326 188.253662,324.395508 
	C189.990662,323.574280 191.397919,322.055664 193.483398,320.421478 
	C194.936005,325.905426 192.987137,328.440948 189.332031,329.818726 
	C185.061020,331.428741 180.766861,331.732269 177.437531,327.853271 
	C174.114075,323.981171 174.342789,318.316772 177.890961,313.383728 
z"/>
<path fill="#37C052" opacity="1.000000" stroke="none" 
	d="
M311.373779,125.725372 
	C310.950592,124.997314 310.357941,123.862610 310.573364,122.910789 
	C314.447235,105.794533 300.722504,93.266327 285.897186,91.865562 
	C280.067535,91.314751 274.336487,89.278511 268.693726,87.496750 
	C267.247589,87.040100 266.307983,84.979156 265.101868,83.299637 
	C266.030426,83.383499 266.992767,83.822968 268.262939,84.322403 
	C273.207947,85.891510 277.883392,87.293625 282.473541,88.934380 
	C289.737152,91.530762 296.943665,94.287018 304.503723,97.085800 
	C306.821106,99.915421 308.809021,102.634857 311.010071,105.625923 
	C311.342316,112.399658 311.461395,118.901749 311.373779,125.725372 
z"/>
<path fill="#37C052" opacity="1.000000" stroke="none" 
	d="
M289.441711,144.138214 
	C289.609528,143.425018 290.035400,142.417862 290.783630,142.025681 
	C295.947845,139.318878 301.179321,136.740372 306.743347,134.081741 
	C303.087677,140.379974 297.030853,143.216415 289.441711,144.138214 
z"/>
<path fill="#37C052" opacity="1.000000" stroke="none" 
	d="
M273.559204,144.939209 
	C277.210815,144.613083 281.309326,144.345856 285.906006,144.120667 
	C282.271454,144.441162 278.138763,144.719620 273.559204,144.939209 
z"/>
<path fill="#3FB85B" opacity="1.000000" stroke="none" 
	d="
M248.102661,134.290359 
	C249.609711,135.123550 251.252441,136.237732 252.928284,137.708649 
	C252.573715,138.005875 252.186050,137.946365 251.517059,137.799179 
	C250.236603,136.664780 249.237488,135.618073 248.102661,134.290359 
z"/>
<path fill="#37C052" opacity="1.000000" stroke="none" 
	d="
M307.489014,133.261810 
	C308.140594,131.640686 309.086578,129.855652 310.372131,128.056458 
	C309.735657,129.727493 308.759521,131.412704 307.489014,133.261810 
z"/>
<path fill="#3FB85B" opacity="1.000000" stroke="none" 
	d="
M253.109467,138.317688 
	C253.915253,138.482758 254.879242,138.923782 255.931366,139.696594 
	C255.102219,139.550125 254.184952,139.071884 253.109467,138.317688 
z"/>
<path fill="#03244D" opacity="1.000000" stroke="none" 
	d="
M181.603180,160.040588 
	C184.403320,163.696442 186.894730,167.201767 189.673889,171.259964 
	C190.545364,172.912796 191.129105,174.012741 191.776367,175.453522 
	C192.297012,176.849670 192.754166,177.904999 193.229889,179.349991 
	C193.324234,180.254303 193.399994,180.768967 192.969360,181.267883 
	C186.011505,181.417709 179.560028,181.583282 173.106491,181.453568 
	C173.045029,180.769882 172.985641,180.381470 172.984467,179.662170 
	C172.902161,178.461197 172.761658,177.591141 172.683350,176.291748 
	C172.712967,172.951645 172.680389,170.040848 172.647812,167.130051 
	C172.915039,163.369507 173.182251,159.608948 173.533844,154.660919 
	C176.549973,156.693207 178.922195,158.291626 181.603180,160.040588 
z"/>
<path fill="#051E41" opacity="1.000000" stroke="none" 
	d="
M160.171921,145.657791 
	C158.173843,146.244614 156.462845,146.544159 154.381165,146.809814 
	C154.728012,137.102341 155.445526,127.428719 156.181198,117.510315 
	C164.004745,126.537170 171.864120,135.605362 179.833344,145.021469 
	C173.448456,145.369751 166.953720,145.370132 160.171921,145.657791 
z"/>
<path fill="#03204A" opacity="1.000000" stroke="none" 
	d="
M148.248260,170.292664 
	C152.764160,164.686310 155.985229,157.798340 163.706436,154.906006 
	C164.087769,163.222107 164.124435,171.655640 163.865692,180.622314 
	C163.387741,181.220261 163.205170,181.285080 162.562439,181.249207 
	C156.410812,181.174698 150.719330,181.200851 144.843796,180.958069 
	C145.855896,177.223648 147.052078,173.758163 148.248260,170.292664 
z"/>
<path fill="#257DBC" opacity="1.000000" stroke="none" 
	d="
M163.022583,181.349884 
	C163.205170,181.285080 163.387741,181.220261 163.854813,181.092636 
	C165.091202,177.565094 166.501358,174.147903 166.866898,170.622391 
	C167.237167,167.051437 168.323380,165.697342 172.236938,167.065018 
	C172.680389,170.040848 172.712967,172.951645 172.386505,176.486191 
	C172.327057,178.070984 172.626663,179.032013 172.926270,179.993057 
	C172.985641,180.381470 173.045029,180.769882 173.079712,181.885284 
	C172.769012,190.050171 170.754593,192.091064 164.473297,191.054169 
	C164.089508,190.821289 163.949509,190.618271 163.832153,189.993408 
	C161.954239,187.276108 160.346176,187.300430 158.563965,190.133423 
	C156.414566,190.180252 154.690384,190.156311 152.922409,189.793915 
	C152.915222,187.302231 152.951813,185.148987 153.434570,182.996552 
	C156.928024,182.448196 159.975296,181.899033 163.022583,181.349884 
z"/>
<path fill="#042248" opacity="1.000000" stroke="none" 
	d="
M229.887695,190.138336 
	C231.353867,193.950485 232.843307,197.734177 234.621628,202.089172 
	C235.235687,202.841904 235.560867,203.023346 235.886047,203.204788 
	C235.886047,203.204788 235.399002,203.256470 235.331421,203.494873 
	C235.930710,208.117767 236.597549,212.502258 237.422272,217.924866 
	C228.754822,217.924866 220.848099,217.924866 212.112762,217.846558 
	C210.801941,216.295547 210.107574,214.855698 209.873703,213.344559 
	C208.774902,206.244751 207.802582,199.125381 206.785095,192.012985 
	C207.051743,191.486893 207.318375,190.960815 208.043076,190.290543 
	C208.672607,190.117752 208.844101,190.089157 209.015610,190.060547 
	C211.379669,190.134293 213.743729,190.208038 217.001389,190.263733 
	C221.902786,190.224380 225.910599,190.203094 229.918396,190.181793 
	C229.918396,190.181793 229.910980,190.109909 229.887695,190.138336 
z"/>
<path fill="#03244D" opacity="1.000000" stroke="none" 
	d="
M196.858627,159.149994 
	C204.012970,164.391891 211.189667,169.587845 218.635803,174.985413 
	C220.156143,176.806747 221.407028,178.426468 223.558075,181.211792 
	C216.864197,181.211792 211.416565,181.211792 205.182343,181.101715 
	C201.287292,174.987289 198.178802,168.982971 195.070328,162.978638 
	C194.723022,161.448273 194.375717,159.917908 194.514694,158.497345 
	C195.660065,158.783218 196.319168,158.959305 196.978256,159.135391 
	C196.978256,159.135391 196.880981,159.104065 196.858627,159.149994 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M211.061203,226.627304 
	C210.873123,226.931595 210.723953,227.008118 210.155640,227.057373 
	C209.173920,227.958679 208.611359,228.887253 208.034698,230.218964 
	C208.025925,231.423172 208.031250,232.224243 208.041397,233.033325 
	C208.046234,233.041367 208.068985,233.064697 207.727844,233.104416 
	C206.235535,233.612228 205.084351,234.080322 203.997574,234.522247 
	C202.880127,232.527954 201.889343,230.759705 200.804413,228.690445 
	C200.142212,227.880066 199.574158,227.370697 198.900772,226.556992 
	C197.527435,226.454803 196.259415,226.656952 194.662476,226.915268 
	C193.891861,226.933578 193.450180,226.895721 192.900238,226.552734 
	C191.658752,225.444672 190.525543,224.641724 188.359726,223.107132 
	C192.451019,221.148438 195.720657,219.583099 199.254578,217.917618 
	C199.989304,217.547653 200.459747,217.277847 201.240799,216.811371 
	C202.364120,214.073074 203.176819,211.531433 203.989532,208.989792 
	C204.435776,208.987869 204.882004,208.985931 205.847565,209.303253 
	C206.995895,211.790619 207.533646,213.990936 208.283173,216.116562 
	C208.868942,217.777771 208.764099,220.574158 211.962845,219.006836 
	C213.330872,220.466476 214.698898,221.926117 215.719940,223.738480 
	C214.580292,224.722412 213.787628,225.353638 212.994965,225.984863 
	C212.363342,226.123077 211.731720,226.261307 211.061203,226.627304 
z"/>
<path fill="#2C8DC4" opacity="1.000000" stroke="none" 
	d="
M193.475739,181.283615 
	C193.399994,180.768967 193.324234,180.254303 193.417389,179.151733 
	C193.713516,177.377045 193.840759,176.190292 193.972717,174.668121 
	C193.979782,173.885300 193.982101,173.437897 193.984421,172.990509 
	C194.513397,172.132202 195.042389,171.273895 196.021545,170.457001 
	C199.172119,174.742279 199.457520,180.629761 204.958191,182.967804 
	C207.035980,186.376633 204.846939,191.608627 200.133835,192.987289 
	C199.502274,192.985687 199.003998,192.969635 198.993546,192.593796 
	C198.941650,188.692047 196.584381,188.657547 193.897522,188.515762 
	C188.820312,188.247864 183.761673,187.627594 178.695328,187.153748 
	C178.627457,186.324707 178.559586,185.495682 178.491714,184.666656 
	C180.906570,184.111816 183.319763,183.549591 185.736725,183.004135 
	C188.314545,182.422363 190.895920,181.856445 193.475739,181.283615 
z"/>
<path fill="#3D99C6" opacity="1.000000" stroke="none" 
	d="
M211.977722,218.774567 
	C208.764099,220.574158 208.868942,217.777771 208.283173,216.116562 
	C207.533646,213.990936 206.995895,211.790619 206.182251,209.300293 
	C206.074554,203.408752 206.151489,197.839386 206.506775,192.141510 
	C207.802582,199.125381 208.774902,206.244751 209.873703,213.344559 
	C210.107574,214.855698 210.801941,216.295547 211.650482,217.922852 
	C212.016846,218.077438 211.992599,218.542313 211.977722,218.774567 
z"/>
<path fill="#3D99C6" opacity="1.000000" stroke="none" 
	d="
M199.003998,192.969635 
	C199.003998,192.969635 199.502274,192.985687 199.751419,192.993759 
	C201.172882,198.145523 202.345200,203.289215 203.753540,208.711349 
	C203.176819,211.531433 202.364120,214.073074 201.073181,216.445618 
	C199.727127,208.848511 198.859314,201.420532 198.194763,193.859131 
	C198.648422,193.512497 198.850418,193.260468 199.003998,192.969635 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M204.976974,182.694824 
	C199.457520,180.629761 199.172119,174.742279 196.239487,170.241760 
	C195.495758,167.700638 194.984268,165.416168 194.771561,163.055176 
	C198.178802,168.982971 201.287292,174.987289 204.721497,181.160034 
	C205.030090,181.692902 205.012924,182.057373 204.976974,182.694824 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M229.817871,189.792862 
	C225.910599,190.203094 221.902786,190.224380 217.435440,190.166534 
	C219.697281,187.426178 225.566345,187.111359 229.817871,189.792862 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M208.981934,189.852631 
	C208.844101,190.089157 208.672607,190.117752 208.249542,190.077698 
	C208.173935,189.672684 208.349930,189.336334 208.525909,188.999969 
	C208.666687,189.214890 208.807480,189.429794 208.981934,189.852631 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M230.938171,176.090851 
	C230.920563,176.135223 230.731873,176.369827 230.731873,176.369827 
	C230.731873,176.369827 230.737411,176.175903 230.802246,176.128860 
	C230.867096,176.081818 230.955795,176.046478 230.938171,176.090851 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M230.509903,307.897827 
	C230.692123,307.449005 231.174927,307.290070 231.657715,307.131104 
	C231.722198,307.403778 231.786682,307.676422 231.851166,307.949097 
	C231.504288,308.028656 231.157394,308.108185 230.509903,307.897827 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M238.269745,299.437012 
	C238.019333,299.642609 237.757492,299.887329 237.495667,300.132050 
	C237.570541,299.852081 237.645447,299.572113 237.982330,299.424377 
	C238.244324,299.556610 238.258331,299.476196 238.269745,299.437012 
z"/>
<path fill="#C3503C" opacity="1.000000" stroke="none" 
	d="
M189.005783,125.976418 
	C190.091476,126.935699 191.177155,127.894981 192.536835,129.005737 
	C196.201553,133.786484 199.592285,138.415756 203.050583,143.137283 
	C199.228592,148.086945 194.390533,145.102722 190.327454,145.310913 
	C184.606705,145.604065 180.287277,142.675903 176.799149,137.734558 
	C171.652954,130.444382 165.680267,123.737648 160.064728,116.357056 
	C160.069901,115.931679 160.287582,115.727417 160.597244,115.864090 
	C162.307556,117.112038 163.932800,118.031891 165.070709,119.367088 
	C170.556351,125.803719 175.777786,132.469131 181.360703,138.817902 
	C184.785507,142.712494 192.644577,143.354462 198.204987,140.341110 
	C196.733612,138.323563 195.188248,136.424255 193.889252,134.369232 
	C192.160980,131.635117 190.624603,128.779709 189.005783,125.976418 
z"/>
<path fill="#C3503C" opacity="1.000000" stroke="none" 
	d="
M155.989929,112.298676 
	C154.197021,110.008286 152.625458,107.959518 151.322952,105.559860 
	C154.490524,108.715118 157.389053,112.221268 160.287582,115.727417 
	C160.287582,115.727417 160.069901,115.931679 159.960800,116.033699 
	C158.638229,114.937241 157.424759,113.738762 155.989929,112.298676 
z"/>
<path fill="#04274B" opacity="1.000000" stroke="none" 
	d="
M158.184097,287.899261 
	C151.740585,282.687622 149.121109,275.023651 145.537140,267.999420 
	C144.843964,266.640869 144.856796,264.922119 145.230103,263.203369 
	C151.931183,263.073456 157.946106,263.109650 164.050491,263.396057 
	C164.402588,264.101105 164.665237,264.556000 164.836655,265.449280 
	C164.804199,273.445038 164.862976,281.002319 164.719467,288.778381 
	C164.282211,289.313568 164.047256,289.630005 163.474304,289.885254 
	C161.485580,289.182465 159.834839,288.540863 158.184097,287.899261 
z"/>
<path fill="#04274B" opacity="1.000000" stroke="none" 
	d="
M130.525772,279.454163 
	C125.053230,274.529816 119.920494,269.755341 114.498230,264.800507 
	C115.265572,264.109222 116.322441,263.598267 118.167358,263.071198 
	C122.399254,263.065155 125.862274,263.304688 129.281464,263.019287 
	C132.541214,262.747162 134.605026,263.859619 135.729462,267.273560 
	C136.486298,269.239471 137.200668,270.827515 137.897797,272.578033 
	C137.880554,272.740509 138.079391,272.999847 138.079391,272.999847 
	C140.129272,277.141785 142.179153,281.283722 144.892807,286.766846 
	C139.528946,284.027863 135.197266,281.815918 130.525772,279.454163 
z"/>
<path fill="#45AED0" opacity="1.000000" stroke="none" 
	d="
M164.921753,288.559631 
	C164.862976,281.002319 164.804199,273.445038 165.199081,265.571686 
	C166.462799,267.743927 167.272858,270.232178 168.082916,272.720459 
	C168.498917,272.794952 168.914917,272.869446 169.330917,272.943970 
	C169.884140,271.475922 170.405579,269.994873 170.999313,268.543396 
	C171.623810,267.016754 172.317490,265.518433 173.034088,264.465576 
	C173.023605,268.617859 172.959946,272.312225 172.896301,276.006622 
	C172.124878,280.141846 171.044907,284.255707 170.756241,288.424347 
	C170.660553,289.806335 172.600342,291.329285 173.888260,293.190643 
	C172.185440,294.186981 170.274475,295.305115 168.172638,296.712250 
	C167.544968,297.000977 167.108170,297.000763 166.340820,296.752014 
	C166.010269,296.503479 166.008881,296.006500 166.093903,295.962402 
	C166.178940,295.918335 166.189774,295.688324 166.189774,295.688324 
	C166.189774,295.688324 165.993286,295.990875 165.634003,295.992798 
	C164.178635,296.329407 163.082535,296.664093 161.986435,296.998779 
	C157.913300,296.376617 153.840164,295.754425 149.380630,295.063080 
	C150.082382,290.546692 153.805588,289.398773 157.838562,288.052673 
	C159.834839,288.540863 161.485580,289.182465 163.741638,289.731934 
	C164.538528,289.279724 164.730133,288.919678 164.921753,288.559631 
z"/>
<path fill="#3595C4" opacity="1.000000" stroke="none" 
	d="
M135.686996,266.895660 
	C134.605026,263.859619 132.541214,262.747162 129.281464,263.019287 
	C125.862274,263.304688 122.399254,263.065155 118.495377,262.994080 
	C122.189224,261.443359 125.835541,257.549469 131.305542,260.128052 
	C131.206451,258.732727 131.100723,257.243958 131.047394,255.158234 
	C131.099808,254.561310 131.209900,254.092834 131.591492,254.051514 
	C132.977997,252.535278 134.654800,251.139099 134.838898,249.567993 
	C135.158417,246.841248 136.218246,245.793243 138.902588,246.422562 
	C139.239929,248.157104 139.488129,249.493103 139.425629,250.995285 
	C139.653015,252.138931 140.191116,253.116379 141.083405,254.169327 
	C142.620041,254.466919 143.802505,254.689026 145.262054,255.158783 
	C148.198166,256.226257 150.857224,257.046051 153.906662,257.986206 
	C151.866119,259.648376 150.435516,260.813751 148.603760,261.982666 
	C147.132492,261.996460 146.062363,262.006683 144.992218,262.016876 
	C142.635559,260.435730 142.061829,261.464264 142.426910,263.790466 
	C142.892227,266.755096 143.388840,269.714813 143.925644,273.005829 
	C142.023102,273.005829 140.431015,273.005829 138.459152,273.002838 
	C138.079391,272.999847 137.880554,272.740509 137.909103,272.140625 
	C137.187439,269.992371 136.437210,268.444000 135.686996,266.895660 
M133.496155,256.527557 
	C133.496155,256.527557 133.480118,256.485748 133.496155,256.527557 
M142.532364,256.505096 
	C142.532364,256.505096 142.485641,256.524048 142.532364,256.505096 
z"/>
<path fill="#3D99C6" opacity="1.000000" stroke="none" 
	d="
M144.990021,262.224945 
	C146.062363,262.006683 147.132492,261.996460 149.068390,261.987366 
	C154.609787,262.374329 159.285416,262.760101 163.961044,263.145874 
	C157.946106,263.109650 151.931183,263.073456 145.455673,262.943176 
	C144.995102,262.849121 144.987823,262.433014 144.990021,262.224945 
z"/>
<path fill="#C3503C" opacity="1.000000" stroke="none" 
	d="
M129.897614,141.354523 
	C129.852127,112.299957 129.889740,83.627617 129.851700,54.497467 
	C135.119095,53.733330 140.463135,53.265949 145.804062,53.299217 
	C146.579865,53.304047 147.340744,55.704834 148.095856,57.883068 
	C148.035416,68.778526 147.987625,78.787842 147.624207,88.613853 
	C146.888290,87.394852 146.091370,86.353302 146.107376,85.324402 
	C146.232254,77.292877 146.305923,69.250298 146.826614,61.239151 
	C147.123474,56.671944 144.665375,55.317482 140.977982,54.977139 
	C133.481277,54.285198 132.062820,55.042500 132.049438,59.928379 
	C131.979416,85.532455 131.972031,111.136826 132.037796,136.740845 
	C132.041733,138.274490 132.660294,141.017105 133.347229,141.135162 
	C136.885345,141.743210 140.673828,142.290833 144.085739,141.541992 
	C145.429489,141.247070 146.720642,137.567703 146.829544,135.369751 
	C147.182877,128.237701 146.892746,121.076340 147.032822,113.929832 
	C147.083237,111.357468 147.651047,108.795242 147.982727,106.228394 
	C148.017822,116.880531 147.833725,127.539909 148.184921,138.181610 
	C148.348618,143.141724 146.525726,144.763428 141.838531,144.135391 
	C139.877106,143.872589 137.849487,144.103745 135.387955,144.178070 
	C133.276184,143.409454 131.628448,142.573090 129.897614,141.354523 
z"/>
<path fill="#45AED0" opacity="1.000000" stroke="none" 
	d="
M220.133331,221.104950 
	C223.100052,219.982361 225.998947,219.259872 228.897842,218.537384 
	C229.244858,219.323715 229.591873,220.110031 229.938889,220.896362 
	C227.447296,222.534454 224.955688,224.172546 222.464096,225.810638 
	C221.664551,224.375458 220.865021,222.940262 220.133331,221.104950 
z"/>
<path fill="#45AED0" opacity="1.000000" stroke="none" 
	d="
M213.335815,225.972229 
	C213.787628,225.353638 214.580292,224.722412 215.685944,224.044983 
	C215.224838,224.652390 214.450760,225.306000 213.335815,225.972229 
z"/>
<path fill="#EC4920" opacity="1.000000" stroke="none" 
	d="
M148.181732,105.960403 
	C147.651047,108.795242 147.083237,111.357468 147.032822,113.929832 
	C146.892746,121.076340 147.182877,128.237701 146.829544,135.369751 
	C146.720642,137.567703 145.429489,141.247070 144.085739,141.541992 
	C140.673828,142.290833 136.885345,141.743210 133.347229,141.135162 
	C132.660294,141.017105 132.041733,138.274490 132.037796,136.740845 
	C131.972031,111.136826 131.979416,85.532455 132.049438,59.928379 
	C132.062820,55.042500 133.481277,54.285198 140.977982,54.977139 
	C144.665375,55.317482 147.123474,56.671944 146.826614,61.239151 
	C146.305923,69.250298 146.232254,77.292877 146.107376,85.324402 
	C146.091370,86.353302 146.888290,87.394852 147.839325,88.849335 
	C150.328094,87.632843 152.286133,85.997559 154.244171,84.362274 
	C154.663727,83.978706 155.083282,83.595131 155.953918,82.884079 
	C156.608627,82.050980 156.812302,81.545349 157.015961,81.039719 
	C163.632828,72.982735 170.249695,64.925743 177.435974,56.499683 
	C181.434738,55.292862 184.865875,54.462276 188.292709,53.614304 
	C189.968094,53.199730 191.635468,52.752792 193.306458,52.320503 
	C193.596100,52.402405 193.891129,52.452679 194.624542,52.739479 
	C195.101028,55.013916 195.144501,57.020206 194.880905,59.182449 
	C185.788834,70.266602 177.072418,81.251251 168.162506,92.076645 
	C166.322296,94.312462 165.800125,96.260452 167.222656,99.057747 
	C167.819305,99.925049 168.424179,100.458900 169.012482,100.997330 
	C168.995895,101.001900 169.011505,100.971176 169.004608,101.345551 
	C169.751984,103.301598 170.249344,105.085678 171.307129,106.428223 
	C174.955719,111.059044 178.722626,115.603424 182.606689,120.038689 
	C184.209213,121.868660 186.223236,123.338280 188.050095,124.971802 
	C188.050095,124.971802 188.005386,125.003151 188.112732,125.208374 
	C188.488632,125.609917 188.757217,125.806229 189.025787,126.002548 
	C189.025787,126.002548 188.998215,126.004509 189.001999,125.990463 
	C190.624603,128.779709 192.160980,131.635117 193.889252,134.369232 
	C195.188248,136.424255 196.733612,138.323563 198.204987,140.341110 
	C192.644577,143.354462 184.785507,142.712494 181.360703,138.817902 
	C175.777786,132.469131 170.556351,125.803719 165.070709,119.367088 
	C163.932800,118.031891 162.307556,117.112038 160.597244,115.864090 
	C157.389053,112.221268 154.490524,108.715118 151.248627,105.237946 
	C150.063736,105.408745 149.222244,105.550575 148.181732,105.960403 
z"/>
<path fill="#752926" opacity="1.000000" stroke="none" 
	d="
M153.984741,83.996475 
	C152.286133,85.997559 150.328094,87.632843 148.154953,89.032639 
	C147.987625,78.787842 148.035416,68.778526 148.132294,58.284012 
	C152.395966,58.740685 153.078445,61.604595 153.021042,65.496170 
	C152.931946,71.536232 153.459290,77.585373 153.984741,83.996475 
z"/>
<path fill="#54313D" opacity="1.000000" stroke="none" 
	d="
M203.555374,59.003365 
	C203.005402,60.771801 202.037521,62.519337 200.981873,64.592339 
	C199.515747,65.943726 198.137375,66.969650 196.450531,67.656586 
	C196.730652,64.558594 197.319260,61.799583 197.957947,59.024155 
	C198.007996,59.007740 198.109695,58.980171 198.109695,58.980171 
	C199.785614,58.980934 201.461548,58.981701 203.555374,59.003365 
z"/>
<path fill="#672D30" opacity="1.000000" stroke="none" 
	d="
M192.833191,52.197922 
	C191.635468,52.752792 189.968094,53.199730 188.292709,53.614304 
	C184.865875,54.462276 181.434738,55.292862 177.636383,56.235184 
	C178.342270,53.063885 180.701553,51.794243 184.027191,51.917698 
	C186.802887,52.020741 189.582199,52.026493 192.833191,52.197922 
z"/>
<path fill="#672D30" opacity="1.000000" stroke="none" 
	d="
M195.187988,59.026497 
	C195.144501,57.020206 195.101028,55.013916 194.849014,52.727036 
	C195.955566,52.446449 197.270630,52.446449 198.877182,52.446449 
	C198.522766,54.768387 198.261932,56.477184 198.055405,58.583076 
	C198.109695,58.980171 198.007996,59.007740 197.618698,59.010746 
	C196.548935,59.018002 195.868454,59.022247 195.187988,59.026497 
z"/>
<path fill="#752926" opacity="1.000000" stroke="none" 
	d="
M156.789932,81.192139 
	C156.812302,81.545349 156.608627,82.050980 156.135132,82.663696 
	C156.098145,82.295372 156.331009,81.819962 156.789932,81.192139 
z"/>
<path fill="#EAF2F5" opacity="1.000000" stroke="none" 
	d="
M101.403549,289.699524 
	C104.656898,292.175262 107.784904,294.624695 111.196129,297.295898 
	C110.379631,298.533447 109.604790,299.707855 108.689774,301.094727 
	C106.874771,299.914886 105.262230,298.866638 103.527931,297.739258 
	C100.696747,301.395935 97.985443,304.897797 95.112709,308.608154 
	C93.444252,307.445404 92.117493,306.520782 90.325912,305.272247 
	C93.023880,301.534821 95.604858,297.959442 98.419579,294.060272 
	C96.981789,292.736969 95.589699,291.455750 93.941742,289.938995 
	C94.742188,288.874298 95.514961,287.846405 96.528191,286.498657 
	C97.935242,287.395996 99.280243,288.253784 100.916672,289.269226 
	C101.208107,289.426849 101.278198,289.673248 101.403549,289.699524 
z"/>
<path fill="#042047" opacity="1.000000" stroke="none" 
	d="
M106.185928,251.298569 
	C104.044220,243.614349 102.145081,236.207611 100.494843,228.202301 
	C104.514900,227.367813 108.286057,227.131882 112.444366,226.948853 
	C113.871872,226.974091 114.912216,226.946396 116.373535,226.979675 
	C118.208397,226.996506 119.622276,226.952362 121.426186,226.958466 
	C122.858887,226.949493 123.901566,226.890259 125.302307,226.889450 
	C126.143814,226.954788 126.627243,226.961700 127.277466,227.346375 
	C128.506378,235.869263 129.568512,244.014374 130.516632,252.475189 
	C130.175507,253.418716 129.948380,254.046509 129.721252,254.674301 
	C123.495399,254.585312 117.257751,254.679810 111.051811,254.280991 
	C109.459343,254.178650 107.966896,252.519806 106.185928,251.298569 
z"/>
<path fill="#175FA9" opacity="1.000000" stroke="none" 
	d="
M130.029327,254.789368 
	C129.948380,254.046509 130.175507,253.418716 130.626434,252.696686 
	C130.970139,253.099258 131.090027,253.596039 131.209900,254.092834 
	C131.209900,254.092834 131.099808,254.561310 131.041534,254.795120 
	C130.767975,254.987427 130.552704,254.945923 130.029327,254.789368 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M193.829239,172.716232 
	C193.982101,173.437897 193.979782,173.885300 193.597321,174.677536 
	C192.715759,175.052490 192.214294,175.082596 191.712845,175.112686 
	C191.129105,174.012741 190.545364,172.912796 189.914764,171.503326 
	C191.136612,171.609848 192.405334,172.025909 193.829239,172.716232 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M196.739655,158.882202 
	C196.319168,158.959305 195.660065,158.783218 194.750076,158.359467 
	C195.166458,158.284195 195.833755,158.456589 196.739655,158.882202 
z"/>
<path fill="#1F70B0" opacity="1.000000" stroke="none" 
	d="
M112.057205,226.895935 
	C108.286057,227.131882 104.514900,227.367813 100.390717,227.818741 
	C99.863029,228.030167 99.688377,228.026627 99.251678,228.015289 
	C100.091949,224.239044 102.647041,224.052048 106.019051,224.721649 
	C110.830795,225.677124 112.601997,223.756729 112.052139,218.393829 
	C116.360413,217.992966 120.723625,217.931946 125.428970,218.282166 
	C122.491203,220.077911 121.347694,222.565872 121.816513,226.108185 
	C121.388908,226.421158 121.191734,226.646500 121.036156,226.908234 
	C119.622276,226.952362 118.208397,226.996506 116.137596,226.776642 
	C114.339516,226.640411 113.198364,226.768173 112.057205,226.895935 
z"/>
<path fill="#1F70B0" opacity="1.000000" stroke="none" 
	d="
M106.116348,218.176239 
	C106.012802,218.063583 106.500175,217.979797 106.744598,218.016602 
	C106.989029,218.053391 106.646172,218.338318 106.646172,218.338318 
	C106.646172,218.338318 106.219894,218.288895 106.116348,218.176239 
z"/>
<path fill="#37C052" opacity="1.000000" stroke="none" 
	d="
M263.870300,80.614029 
	C260.464539,77.603752 259.733551,73.661110 263.105774,70.777725 
	C265.705414,68.554901 269.528259,67.762642 272.868530,66.593109 
	C263.916870,70.696907 262.259430,73.136269 263.870300,80.614029 
z"/>
<path fill="#37C052" opacity="1.000000" stroke="none" 
	d="
M276.477661,66.803917 
	C277.993958,63.659660 280.215027,65.238281 282.644836,66.601730 
	C280.888031,66.849197 278.895142,66.856697 276.477661,66.803917 
z"/>
<path fill="#37C052" opacity="1.000000" stroke="none" 
	d="
M286.903625,67.360374 
	C287.278870,67.291229 287.897919,67.424576 288.734070,67.792397 
	C288.349945,67.872208 287.748657,67.717545 286.903625,67.360374 
z"/>
<path fill="#752926" opacity="1.000000" stroke="none" 
	d="
M194.880905,59.182446 
	C195.868454,59.022247 196.548935,59.018002 197.568634,59.027161 
	C197.319260,61.799583 196.730652,64.558594 196.126083,67.700134 
	C196.110123,68.082664 196.082565,68.063034 195.706238,68.094894 
	C194.258347,68.829063 192.698318,69.283188 192.194809,70.274132 
	C189.032639,76.497604 180.869324,78.501007 179.126282,86.000000 
	C178.847778,87.198235 176.623642,87.968117 175.265961,88.887283 
	C170.618027,92.033943 169.380478,95.454704 171.242599,100.100800 
	C171.213181,100.543167 171.183746,100.985535 170.828094,101.633446 
	C170.005066,101.549713 169.508286,101.260445 169.011505,100.971176 
	C169.011505,100.971176 168.995895,101.001900 168.957153,100.692795 
	C168.355911,99.830551 167.793396,99.277428 167.230881,98.724297 
	C165.800125,96.260452 166.322296,94.312462 168.162506,92.076645 
	C177.072418,81.251251 185.788834,70.266602 194.880905,59.182446 
z"/>
<path fill="#54313D" opacity="1.000000" stroke="none" 
	d="
M171.497986,99.903252 
	C169.380478,95.454704 170.618027,92.033943 175.265961,88.887283 
	C176.623642,87.968117 178.847778,87.198235 179.126282,86.000000 
	C180.869324,78.501007 189.032639,76.497604 192.194809,70.274132 
	C192.698318,69.283188 194.258347,68.829063 195.694275,68.096619 
	C195.071518,70.136063 194.084396,72.205643 193.016602,74.612885 
	C187.066284,81.838936 181.199402,88.729668 175.324661,95.613724 
	C174.149521,96.990761 172.944565,98.342369 171.497986,99.903252 
z"/>
<path fill="#C3503C" opacity="1.000000" stroke="none" 
	d="
M169.004608,101.345551 
	C169.508286,101.260445 170.005066,101.549713 170.765228,101.960083 
	C176.679321,109.476097 182.330032,116.871025 188.015411,124.618881 
	C186.223236,123.338280 184.209213,121.868660 182.606689,120.038689 
	C178.722626,115.603424 174.955719,111.059044 171.307129,106.428223 
	C170.249344,105.085678 169.751984,103.301598 169.004608,101.345551 
z"/>
<path fill="#C3503C" opacity="1.000000" stroke="none" 
	d="
M188.953049,125.749771 
	C188.757217,125.806229 188.488632,125.609917 188.117920,125.181587 
	C188.303955,125.132042 188.592133,125.314514 188.953049,125.749771 
z"/>
<path fill="#FBF208" opacity="1.000000" stroke="none" 
	d="
M386.039215,138.041519 
	C385.534790,143.122086 382.222473,143.444046 378.185638,142.795029 
	C370.739288,141.597824 370.069122,141.110550 370.061615,135.371445 
	C370.029419,110.777390 369.968262,86.182610 370.171234,61.590195 
	C370.191254,59.164848 371.262207,55.707516 373.032745,54.557804 
	C376.525940,52.289524 383.663940,54.327015 385.966034,57.999378 
	C386.004883,63.068249 385.958435,67.361267 385.962067,71.654243 
	C385.980713,93.783333 386.012207,115.912430 386.039215,138.041519 
z"/>
<path fill="#667129" opacity="1.000000" stroke="none" 
	d="
M390.951843,145.948990 
	C389.721069,145.542419 388.490295,145.135849 387.063965,144.461929 
	C386.913147,143.993057 386.957886,143.791519 387.296204,142.974579 
	C388.391937,134.493393 389.708160,126.639359 389.888062,118.759392 
	C390.251007,102.859970 390.115021,86.944939 389.913574,71.039780 
	C389.862610,67.016602 388.789948,63.006344 388.186768,58.990147 
	C389.480743,59.331154 390.774719,59.672169 392.380920,60.129028 
	C392.732727,86.178680 392.772278,112.112480 392.514160,138.749344 
	C391.794983,141.617935 391.373413,143.783463 390.951843,145.948990 
z"/>
<path fill="#6E7215" opacity="1.000000" stroke="none" 
	d="
M388.152161,58.874538 
	C388.789948,63.006344 389.862610,67.016602 389.913574,71.039780 
	C390.115021,86.944939 390.251007,102.859970 389.888062,118.759392 
	C389.708160,126.639359 388.391937,134.493393 387.375427,142.675232 
	C387.112427,141.629852 387.063843,140.268387 386.999786,138.024567 
	C386.992523,130.996643 387.027710,124.850967 387.003876,118.705528 
	C386.925323,98.446976 386.825409,78.188507 386.733734,57.930004 
	C386.733734,57.930004 386.936188,57.854462 387.033020,57.901260 
	C387.295197,58.036209 387.460480,58.124352 387.865967,58.365162 
	C388.106171,58.517830 388.117554,58.758926 388.152161,58.874538 
z"/>
<path fill="#EEEE37" opacity="1.000000" stroke="none" 
	d="
M386.575989,57.934124 
	C386.825409,78.188507 386.925323,98.446976 387.003876,118.705528 
	C387.027710,124.850967 386.992523,130.996643 386.938965,137.620636 
	C386.893585,138.099075 386.466705,138.059357 386.252960,138.050446 
	C386.012207,115.912430 385.980713,93.783333 385.962067,71.654243 
	C385.958435,67.361267 386.004883,63.068249 386.065460,58.359444 
	C386.102692,57.943638 386.418243,57.938244 386.575989,57.934124 
z"/>
<path fill="#49AECE" opacity="1.000000" stroke="none" 
	d="
M235.758194,202.964966 
	C235.560867,203.023346 235.235687,202.841904 234.855804,202.348679 
	C235.077499,202.266312 235.353928,202.495743 235.758194,202.964966 
z"/>
<path fill="#05324F" opacity="1.000000" stroke="none" 
	d="
M304.172699,434.800110 
	C306.406403,432.437378 309.014954,431.099976 311.087585,433.690369 
	C312.250153,435.143372 312.739319,438.263611 311.945526,439.827332 
	C311.193024,441.309662 308.196350,442.738068 306.492371,442.440338 
	C302.081329,441.669586 304.573944,437.751251 304.172699,434.800110 
z"/>
<path fill="#3FB85B" opacity="1.000000" stroke="none" 
	d="
M304.415955,68.822952 
	C304.939575,67.470108 305.725616,65.928810 306.833191,64.249878 
	C306.329254,65.619659 305.503815,67.127083 304.415955,68.822952 
z"/>
<path fill="#05324F" opacity="1.000000" stroke="none" 
	d="
M362.260132,440.942474 
	C360.466583,441.522552 358.262054,442.526733 357.494049,441.864594 
	C356.415649,440.934845 355.829254,438.663544 356.099823,437.157806 
	C356.367737,435.666565 357.721832,433.964539 359.073242,433.246155 
	C359.743347,432.890015 362.178406,434.278870 362.563507,435.334656 
	C363.128113,436.882568 362.569946,438.839966 362.260132,440.942474 
z"/>
<path fill="#465A41" opacity="1.000000" stroke="none" 
	d="
M391.020874,146.268524 
	C391.373413,143.783463 391.794983,141.617935 392.485168,139.215576 
	C392.801178,141.728973 392.848572,144.479187 392.829834,147.681610 
	C392.205811,147.618561 391.647858,147.103302 391.020874,146.268524 
z"/>
<path fill="#4AB9D4" opacity="1.000000" stroke="none" 
	d="
M162.229340,297.216614 
	C163.082535,296.664093 164.178635,296.329407 165.637360,295.997375 
	C166.000000,296.000000 166.008881,296.006500 166.008881,296.006500 
	C166.008881,296.006500 166.010269,296.503479 166.013016,296.751984 
	C164.834579,297.145142 163.653412,297.289825 162.229340,297.216614 
z"/>
<path fill="#3FB85B" opacity="1.000000" stroke="none" 
	d="
M246.907257,132.350479 
	C247.273773,132.509537 247.611511,132.974060 247.970917,133.735550 
	C247.621231,133.573654 247.249863,133.114807 246.907257,132.350479 
z"/>
<path fill="#45AED0" opacity="1.000000" stroke="none" 
	d="
M200.898560,228.991455 
	C201.889343,230.759705 202.880127,232.527954 203.997574,234.522247 
	C205.084351,234.080322 206.235535,233.612228 207.740875,233.519958 
	C208.354233,236.256134 208.613419,238.616470 208.872620,240.976807 
	C208.731277,242.092407 208.589935,243.208008 208.236633,244.867081 
	C207.956879,245.625443 207.963104,245.838181 208.043350,246.048782 
	C208.043350,246.048782 208.054550,246.532806 207.647949,246.828674 
	C206.537430,248.728745 205.833542,250.332947 205.129654,251.937164 
	C203.750748,254.474945 203.534225,258.164001 199.081329,257.620789 
	C198.549042,257.555847 197.848755,259.304169 197.139938,260.138641 
	C196.273209,261.159027 195.315323,262.102020 193.569138,263.134705 
	C186.268555,263.187561 179.795685,263.184174 173.129028,262.974548 
	C174.786835,261.443146 176.638397,260.117981 178.505051,258.782013 
	C177.285858,257.425720 176.149826,256.161957 175.474518,254.807220 
	C182.711121,254.716232 189.487015,254.716232 197.052200,254.716232 
	C198.338287,246.114731 199.618423,237.553101 200.898560,228.991455 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M205.142120,252.304230 
	C205.833542,250.332947 206.537430,248.728745 207.602905,247.066513 
	C207.794037,249.083557 207.623627,251.158630 207.406021,253.808212 
	C210.728149,254.008392 214.027618,254.207214 217.660904,254.622513 
	C212.066391,257.661316 206.784302,256.769562 205.142120,252.304230 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M208.979279,240.574814 
	C208.613419,238.616470 208.354233,236.256134 208.082016,233.480255 
	C208.068985,233.064697 208.046234,233.041367 208.257416,232.819763 
	C208.328659,231.670715 208.188721,230.743271 208.048798,229.815811 
	C208.611359,228.887253 209.173920,227.958679 209.922165,227.150848 
	C209.767212,231.572006 209.426590,235.872406 208.979279,240.574814 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M208.156601,245.914673 
	C207.963104,245.838181 207.956879,245.625443 208.097137,245.266769 
	C208.346573,245.320282 208.380005,245.539474 208.156601,245.914673 
z"/>
<path fill="#04274B" opacity="1.000000" stroke="none" 
	d="
M173.322815,263.180756 
	C179.795685,263.184174 186.268555,263.187561 193.182495,263.185333 
	C193.610519,263.492615 193.630997,263.803284 193.546997,264.499054 
	C193.325485,265.249817 193.241959,265.613281 193.158417,265.976746 
	C190.087540,271.462952 187.429871,277.244598 183.795135,282.327606 
	C181.513336,285.518585 177.764511,287.660522 174.673874,290.273132 
	C174.132202,290.016113 173.590515,289.759094 173.048843,289.502045 
	C173.048843,285.308807 173.048843,281.115540 172.972565,276.464478 
	C172.959946,272.312225 173.023605,268.617859 173.154236,264.273132 
	C173.221191,263.622833 173.322815,263.180756 173.322815,263.180756 
z"/>
<path fill="#45AED0" opacity="1.000000" stroke="none" 
	d="
M193.337128,265.771240 
	C193.241959,265.613281 193.325485,265.249817 193.640717,264.713684 
	C193.753555,264.882599 193.634689,265.224152 193.337128,265.771240 
z"/>
<path fill="#1F70B0" opacity="1.000000" stroke="none" 
	d="
M152.988403,182.995728 
	C152.951813,185.148987 152.915222,187.302231 152.473480,189.857620 
	C150.580612,190.276093 149.065186,190.484375 147.610214,190.274200 
	C142.453613,189.529251 139.946823,191.708206 139.419449,196.858444 
	C138.764023,203.259247 137.783234,209.626724 136.616272,215.893463 
	C135.952652,214.772614 135.618378,213.766022 135.278152,212.761429 
	C134.792419,211.327179 134.302292,209.894440 133.814041,208.461044 
	C132.867264,209.776138 131.895065,211.073868 130.980453,212.410950 
	C129.948364,213.919754 128.968048,215.463959 127.965218,216.992767 
	C128.733536,209.216919 129.501846,201.441086 130.488098,193.080536 
	C130.609299,192.009018 130.512558,191.522186 130.415817,191.035339 
	C127.793663,190.836670 125.171501,190.638000 122.242607,190.268250 
	C125.430092,188.758713 129.050034,187.660995 132.344772,185.940536 
	C133.383362,185.398209 133.550583,183.187180 134.064392,181.403702 
	C134.530609,179.210373 135.049072,177.353210 135.859772,175.281418 
	C141.618256,177.883759 142.674393,177.544601 145.253662,172.568665 
	C145.801529,171.511703 146.927399,170.754333 148.017624,170.075806 
	C147.052078,173.758163 145.855896,177.223648 144.989548,181.289780 
	C147.875717,182.258850 150.432053,182.627289 152.988403,182.995728 
z"/>
<path fill="#2482C1" opacity="1.000000" stroke="none" 
	d="
M127.748032,217.168182 
	C128.968048,215.463959 129.948364,213.919754 130.980453,212.410950 
	C131.895065,211.073868 132.867264,209.776138 133.814041,208.461044 
	C134.302292,209.894440 134.792419,211.327179 135.278152,212.761429 
	C135.618378,213.766022 135.952652,214.772614 136.693054,216.181000 
	C137.403305,217.070526 137.710098,217.557358 138.113174,218.388153 
	C140.470078,219.483261 142.730682,220.234406 144.992889,221.387115 
	C144.991440,222.859528 144.988388,223.930374 144.553421,225.001526 
	C141.773041,225.739487 139.424591,226.477142 137.055130,227.415405 
	C137.034119,227.616028 136.901627,227.997086 136.562775,228.126022 
	C135.820221,229.089279 135.475479,229.959061 134.999741,230.750061 
	C134.171371,232.127335 133.262222,233.456009 132.386948,234.805054 
	C131.588165,233.487091 130.837997,232.136322 129.977234,230.860184 
	C129.077103,229.525635 128.070648,228.262802 127.110672,226.968613 
	C126.627243,226.961700 126.143814,226.954788 125.056747,226.696899 
	C123.637192,226.292007 122.821266,226.138077 122.005348,225.984146 
	C121.347694,222.565872 122.491203,220.077911 125.889038,218.358551 
	C126.514938,217.796982 127.022888,217.570282 127.748032,217.168182 
z"/>
<path fill="#175FA9" opacity="1.000000" stroke="none" 
	d="
M130.358978,191.336288 
	C130.512558,191.522186 130.609299,192.009018 130.612854,192.661926 
	C130.447144,192.431076 130.374634,192.034149 130.358978,191.336288 
z"/>
<path fill="#03244D" opacity="1.000000" stroke="none" 
	d="
M137.266785,171.776520 
	C137.483536,170.709396 137.968292,169.460068 138.804718,168.132446 
	C138.615860,169.234222 138.075333,170.414276 137.266785,171.776520 
z"/>
<path fill="#1F70B0" opacity="1.000000" stroke="none" 
	d="
M131.600754,181.326111 
	C131.107269,181.755524 130.256332,182.092545 129.212067,182.069244 
	C129.018753,181.708908 129.040955,181.371185 129.040955,181.371185 
	C129.775070,181.325363 130.509186,181.279541 131.600754,181.326111 
z"/>
<path fill="#175FA9" opacity="1.000000" stroke="none" 
	d="
M128.682983,181.274231 
	C129.040955,181.371185 129.018753,181.708908 128.998444,181.877472 
	C128.193466,181.913055 127.408783,181.780075 126.378876,181.484528 
	C126.864113,181.273743 127.594574,181.225510 128.682983,181.274231 
z"/>
<path fill="#17283F" opacity="1.000000" stroke="none" 
	d="
M213.247559,315.872498 
	C212.388931,319.189209 209.951950,320.924896 207.812332,319.019745 
	C206.009613,317.414581 204.738693,313.764709 205.223740,311.434418 
	C205.734802,308.979156 208.943619,308.068207 211.131149,310.603668 
	C212.237503,311.885986 212.573776,313.832672 213.247559,315.872498 
z"/>
<path fill="#2C80B7" opacity="1.000000" stroke="none" 
	d="
M198.993546,192.593796 
	C198.850418,193.260468 198.648422,193.512497 197.961975,193.550552 
	C195.972885,192.495895 194.462006,190.961029 192.859024,190.857925 
	C187.728699,190.527954 182.559662,190.858963 177.414658,190.677094 
	C174.140198,190.561356 173.039917,191.856934 173.099854,195.061432 
	C173.236542,202.368088 173.100922,209.679871 172.699997,216.857941 
	C171.883560,212.434464 171.561768,208.125397 170.942902,203.859421 
	C170.702576,202.202713 169.791229,200.643341 169.185562,199.039612 
	C168.451981,199.226929 167.718399,199.414230 166.984818,199.601532 
	C166.984818,203.793076 167.189621,207.999741 166.882263,212.168625 
	C166.762253,213.796326 165.490646,215.339127 164.689301,216.459320 
	C164.637360,207.710617 164.639694,199.423218 164.642029,191.135803 
	C170.754593,192.091064 172.769012,190.050171 173.081787,182.180573 
	C179.560028,181.583282 186.011505,181.417709 192.969360,181.267883 
	C190.895920,181.856445 188.314545,182.422363 185.736725,183.004135 
	C183.319763,183.549591 180.906570,184.111816 178.491714,184.666656 
	C178.559586,185.495682 178.627457,186.324707 178.695328,187.153748 
	C183.761673,187.627594 188.820312,188.247864 193.897522,188.515762 
	C196.584381,188.657547 198.941650,188.692047 198.993546,192.593796 
z"/>
<path fill="#2C80B7" opacity="1.000000" stroke="none" 
	d="
M191.776367,175.453522 
	C192.214294,175.082596 192.715759,175.052490 193.592606,175.012970 
	C193.840759,176.190292 193.713516,177.377045 193.398804,178.762054 
	C192.754166,177.904999 192.297012,176.849670 191.776367,175.453522 
z"/>
<path fill="#2C80B7" opacity="1.000000" stroke="none" 
	d="
M172.984467,179.662170 
	C172.626663,179.032013 172.327057,178.070984 172.324310,176.915497 
	C172.761658,177.591141 172.902161,178.461197 172.984467,179.662170 
z"/>
<path fill="#256FAD" opacity="1.000000" stroke="none" 
	d="
M153.434570,182.996552 
	C150.432053,182.627289 147.875717,182.258850 145.173615,181.558716 
	C150.719330,181.200851 156.410812,181.174698 162.562439,181.249207 
	C159.975296,181.899033 156.928024,182.448196 153.434570,182.996552 
z"/>
<path fill="#042047" opacity="1.000000" stroke="none" 
	d="
M164.473297,191.054169 
	C164.639694,199.423218 164.637360,207.710617 164.522552,216.609726 
	C164.410080,217.221420 164.144928,217.585358 163.918823,217.551559 
	C163.381760,217.569366 163.115326,217.707108 162.432678,217.892670 
	C153.986923,217.917618 146.001892,217.980896 138.016876,218.044189 
	C137.710098,217.557358 137.403305,217.070526 137.019745,216.296143 
	C137.783234,209.626724 138.764023,203.259247 139.419449,196.858444 
	C139.946823,191.708206 142.453613,189.529251 147.610214,190.274200 
	C149.065186,190.484375 150.580612,190.276093 152.517242,190.196045 
	C154.690384,190.156311 156.414566,190.180252 158.968277,190.205185 
	C161.160034,190.258591 162.522293,190.311035 163.884537,190.363464 
	C163.949509,190.618271 164.089508,190.821289 164.473297,191.054169 
z"/>
<path fill="#2C80B7" opacity="1.000000" stroke="none" 
	d="
M163.832153,189.993408 
	C162.522293,190.311035 161.160034,190.258591 159.393494,190.134399 
	C160.346176,187.300430 161.954239,187.276108 163.832153,189.993408 
z"/>
<path fill="#042248" opacity="1.000000" stroke="none" 
	d="
M200.804413,228.690445 
	C199.618423,237.553101 198.338287,246.114731 197.052200,254.716232 
	C189.487015,254.716232 182.711121,254.716232 175.232513,254.611420 
	C174.005905,253.667419 173.482040,252.828217 173.036652,251.526535 
	C173.115112,243.216278 173.115112,235.368515 173.115112,226.857864 
	C180.237900,226.857864 186.623184,226.857864 193.008484,226.857849 
	C193.450180,226.895721 193.891861,226.933578 195.060532,226.983643 
	C196.860367,226.951004 197.933243,226.906158 199.006104,226.861328 
	C199.574158,227.370697 200.142212,227.880066 200.804413,228.690445 
z"/>
<path fill="#042248" opacity="1.000000" stroke="none" 
	d="
M173.075943,216.989563 
	C173.100922,209.679871 173.236542,202.368088 173.099854,195.061432 
	C173.039917,191.856934 174.140198,190.561356 177.414658,190.677094 
	C182.559662,190.858963 187.728699,190.527954 192.859024,190.857925 
	C194.462006,190.961029 195.972885,192.495895 197.758728,193.683960 
	C198.859314,201.420532 199.727127,208.848511 200.762573,216.642273 
	C200.459747,217.277847 199.989304,217.547653 198.790527,217.854187 
	C189.762054,217.803757 181.461929,217.716599 173.181519,217.464203 
	C173.201218,217.298981 173.075943,216.989563 173.075943,216.989563 
z"/>
<path fill="#3D99C6" opacity="1.000000" stroke="none" 
	d="
M173.161804,217.629425 
	C181.461929,217.716599 189.762054,217.803757 198.526245,217.954346 
	C195.720657,219.583099 192.451019,221.148438 188.359726,223.107132 
	C190.525543,224.641724 191.658752,225.444672 192.900238,226.552734 
	C186.623184,226.857864 180.237900,226.857864 173.115112,226.857864 
	C173.115112,235.368515 173.115112,243.216278 172.805038,251.214081 
	C171.996643,243.576263 171.498322,235.788437 171.087524,227.663300 
	C175.889496,224.924057 177.942795,221.976898 173.161804,217.629425 
z"/>
<path fill="#3D99C6" opacity="1.000000" stroke="none" 
	d="
M198.900772,226.556992 
	C197.933243,226.906158 196.860367,226.951004 195.389465,226.927475 
	C196.259415,226.656952 197.527435,226.454803 198.900772,226.556992 
z"/>
<path fill="#45AED0" opacity="1.000000" stroke="none" 
	d="
M208.034698,230.218964 
	C208.188721,230.743271 208.328659,231.670715 208.252579,232.811737 
	C208.031250,232.224243 208.025925,231.423172 208.034698,230.218964 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M171.000000,228.000610 
	C171.498322,235.788437 171.996643,243.576263 172.726562,251.676544 
	C173.482040,252.828217 174.005905,253.667419 174.771790,254.702423 
	C176.149826,256.161957 177.285858,257.425720 178.505051,258.782013 
	C176.638397,260.117981 174.786835,261.443146 173.129028,262.974548 
	C173.322815,263.180756 173.221191,263.622833 173.101059,263.815277 
	C172.317490,265.518433 171.623810,267.016754 170.999313,268.543396 
	C170.405579,269.994873 169.884140,271.475922 169.330917,272.943970 
	C168.914917,272.869446 168.498917,272.794952 168.082916,272.720459 
	C167.272858,270.232178 166.462799,267.743927 165.290314,265.133240 
	C164.665237,264.556000 164.402588,264.101105 164.050491,263.396057 
	C159.285416,262.760101 154.609787,262.374329 149.469528,261.983826 
	C150.435516,260.813751 151.866119,259.648376 153.906662,257.986206 
	C150.857224,257.046051 148.198166,256.226257 145.725983,255.080353 
	C149.059555,254.727371 152.225174,254.905151 155.347672,254.616455 
	C158.521469,254.322998 161.652771,253.569672 164.930176,252.965561 
	C165.152206,252.801773 165.347092,252.621964 165.325043,252.589508 
	C165.174103,252.367310 164.977692,252.176025 164.675293,251.684845 
	C164.659958,250.587738 164.765259,249.780533 165.133377,248.705963 
	C166.056961,246.058975 167.351898,243.644379 167.222870,241.308395 
	C167.049667,238.172256 165.776108,235.096893 164.880524,231.602020 
	C164.825989,230.136444 164.865814,229.064316 165.263351,228.009933 
	C167.414032,228.018661 169.207016,228.009644 171.000000,228.000610 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M164.719452,288.778381 
	C164.730133,288.919678 164.538528,289.279724 164.079620,289.793152 
	C164.047256,289.630005 164.282211,289.313568 164.719452,288.778381 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M135.729462,267.273560 
	C136.437210,268.444000 137.187439,269.992371 137.926346,271.978149 
	C137.200668,270.827515 136.486298,269.239471 135.729462,267.273560 
z"/>
<path fill="#4AB9D4" opacity="1.000000" stroke="none" 
	d="
M165.996643,295.995422 
	C165.993286,295.990875 166.189774,295.688324 166.189774,295.688324 
	C166.189774,295.688324 166.178940,295.918335 166.093903,295.962402 
	C166.008881,296.006500 166.000000,296.000000 165.996643,295.995422 
z"/>
<path fill="#042047" opacity="1.000000" stroke="none" 
	d="
M164.802673,253.017731 
	C161.652771,253.569672 158.521469,254.322998 155.347672,254.616455 
	C152.225174,254.905151 149.059555,254.727371 145.448914,254.832703 
	C143.802505,254.689026 142.620041,254.466919 141.084259,253.877991 
	C140.399399,252.617142 140.067871,251.723114 139.736328,250.829102 
	C139.488129,249.493103 139.239929,248.157104 138.956268,246.002563 
	C138.247757,239.455032 137.574692,233.726059 136.901627,227.997086 
	C136.901627,227.997086 137.034119,227.616028 137.516266,227.368164 
	C141.147675,227.099808 144.301041,227.174118 147.445190,227.036804 
	C152.325104,226.823730 157.198730,226.466522 162.377411,226.158905 
	C163.421753,226.762344 164.163696,227.377258 164.905655,227.992172 
	C164.865814,229.064316 164.825989,230.136444 164.765076,232.058640 
	C164.786194,238.263580 164.828369,243.618454 164.870544,248.973343 
	C164.765259,249.780533 164.659958,250.587738 164.595001,251.946198 
	C164.691147,252.670883 164.746918,252.844315 164.802673,253.017731 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M136.562775,228.126022 
	C137.574692,233.726059 138.247757,239.455032 138.867126,245.604004 
	C136.218246,245.793243 135.158417,246.841248 134.838898,249.567993 
	C134.654800,251.139099 132.977997,252.535278 131.591492,254.051514 
	C131.090027,253.596039 130.970139,253.099258 130.740463,252.380981 
	C129.568512,244.014374 128.506378,235.869263 127.277458,227.346375 
	C128.070648,228.262802 129.077103,229.525635 129.977234,230.860184 
	C130.837997,232.136322 131.588165,233.487091 132.386948,234.805054 
	C133.262222,233.456009 134.171371,232.127335 134.999741,230.750061 
	C135.475479,229.959061 135.820221,229.089279 136.562775,228.126022 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M139.425629,250.995285 
	C140.067871,251.723114 140.399399,252.617142 140.730087,253.802490 
	C140.191116,253.116379 139.653015,252.138931 139.425629,250.995285 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M133.488129,256.506653 
	C133.480118,256.485748 133.496155,256.527557 133.488129,256.506653 
z"/>
<path fill="#3EA2CB" opacity="1.000000" stroke="none" 
	d="
M142.509003,256.514587 
	C142.485641,256.524048 142.532364,256.505096 142.509003,256.514587 
z"/>
<path fill="#C3503C" opacity="1.000000" stroke="none" 
	d="
M167.222656,99.057747 
	C167.793396,99.277428 168.355911,99.830551 168.973740,100.688217 
	C168.424179,100.458900 167.819305,99.925049 167.222656,99.057747 
z"/>
<path fill="#1F73B9" opacity="1.000000" stroke="none" 
	d="
M121.816513,226.108185 
	C122.821266,226.138077 123.637192,226.292007 124.698669,226.638489 
	C123.901566,226.890259 122.858887,226.949493 121.426186,226.958481 
	C121.191734,226.646500 121.388908,226.421158 121.816513,226.108185 
z"/>
<path fill="#1F73B9" opacity="1.000000" stroke="none" 
	d="
M112.444366,226.948853 
	C113.198364,226.768173 114.339516,226.640411 115.716614,226.715668 
	C114.912216,226.946396 113.871872,226.974091 112.444366,226.948853 
z"/>
<path fill="#2C8DC4" opacity="1.000000" stroke="none" 
	d="
M165.263351,228.009933 
	C164.163696,227.377258 163.421753,226.762344 162.056091,225.882721 
	C155.950058,225.412415 150.467697,225.206818 144.985352,225.001221 
	C144.988388,223.930374 144.991440,222.859528 145.327942,221.144775 
	C150.436234,220.403549 155.221542,220.454147 159.979385,220.116913 
	C161.368500,220.018463 162.679291,218.814926 164.061646,217.986115 
	C164.097321,217.854294 164.144928,217.585358 164.144928,217.585358 
	C164.144928,217.585358 164.410080,217.221420 164.576813,217.071014 
	C165.490646,215.339127 166.762253,213.796326 166.882263,212.168625 
	C167.189621,207.999741 166.984818,203.793076 166.984818,199.601532 
	C167.718399,199.414230 168.451981,199.226929 169.185562,199.039612 
	C169.791229,200.643341 170.702576,202.202713 170.942902,203.859421 
	C171.561768,208.125397 171.883560,212.434464 172.699997,216.857941 
	C173.075943,216.989563 173.201218,217.298981 173.181519,217.464203 
	C177.942795,221.976898 175.889496,224.924057 171.087524,227.663300 
	C169.207016,228.009644 167.414032,228.018661 165.263351,228.009933 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M164.025970,218.117920 
	C162.679291,218.814926 161.368500,220.018463 159.979385,220.116913 
	C155.221542,220.454147 150.436234,220.403549 145.326324,220.743210 
	C142.730682,220.234406 140.470078,219.483261 138.113174,218.388153 
	C146.001892,217.980896 153.986923,217.917618 162.715271,217.943741 
	C163.647736,218.061417 163.836853,218.089676 164.025970,218.117920 
z"/>
<path fill="#3083B7" opacity="1.000000" stroke="none" 
	d="
M144.553421,225.001526 
	C150.467697,225.206818 155.950058,225.412415 161.753708,225.894196 
	C157.198730,226.466522 152.325104,226.823730 147.445190,227.036804 
	C144.301041,227.174118 141.147675,227.099808 137.537292,227.167557 
	C139.424591,226.477142 141.773041,225.739487 144.553421,225.001526 
z"/>
<path fill="#1F70B0" opacity="1.000000" stroke="none" 
	d="
M164.061646,217.986115 
	C163.836853,218.089676 163.647736,218.061417 163.176010,217.982086 
	C163.115326,217.707108 163.381760,217.569366 163.918823,217.551559 
	C164.144928,217.585358 164.097321,217.854294 164.061646,217.986115 
z"/>
<path fill="#3D99C6" opacity="1.000000" stroke="none" 
	d="
M165.133377,248.705963 
	C164.828369,243.618454 164.786194,238.263580 164.859467,232.452057 
	C165.776108,235.096893 167.049667,238.172256 167.222870,241.308395 
	C167.351898,243.644379 166.056961,246.058975 165.133377,248.705963 
z"/>
<path fill="#3D99C6" opacity="1.000000" stroke="none" 
	d="
M164.930176,252.965561 
	C164.746918,252.844315 164.691147,252.670883 164.715637,252.236115 
	C164.977692,252.176025 165.174103,252.367310 165.325043,252.589508 
	C165.347092,252.621964 165.152206,252.801773 164.930176,252.965561 
z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
